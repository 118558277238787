// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { ABNER_LIST_QUERY, } from '@haaretz/graphql';
import { type ComponentPropResponsiveObject, } from '@haaretz/htz-css-tools';
import Paginator from '../../Paginator';
import { OpEdTeaser, } from '../Spawn/SpawnView';
import type { ColorPropType, } from '../../../../flowTypes/ColorPropType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import setBiAction from '../../../../utils/setBiAction';

type PropsType = {
  listId: string,
  history: ?Array<string>,
  biAction: ?ListBiActionType,
  isLazyloadImages: boolean,
  namedBgc: ?ColorPropType,
  gridGap: ?string | Array<ComponentPropResponsiveObject<string>>,
  colGap: ?string | Array<ComponentPropResponsiveObject<string>>,
  rowGap: ?string | Array<ComponentPropResponsiveObject<string>>,
  padding: ?string | Array<ComponentPropResponsiveObject<string>>,
};

export default function AbnerPaginator({
  listId,
  history,
  biAction,
  isLazyloadImages,
  gridGap,
  colGap,
  rowGap,
  padding,
  namedBgc,
}: PropsType) {
  const { theme, } = useFela();
  return (
    <Paginator
      initialPage={1}
      listId={listId}
      history={history}
      listQuery={ABNER_LIST_QUERY}
      colTemplate={theme.abnerStyle.abnerPaginator.colTemplate}
      rowTemplate={null}
      gridGap={gridGap}
      colGap={colGap}
      rowGap={rowGap}
      padding={padding}
      namedBgc={namedBgc}
    >
      {items => items.map((item, idx) => {
        const col = [
          { until: 'l', value: 1, },
          { from: 'l', value: idx % 2 === 0 ? '2/4' : '4/6', },
        ];

        return (
          <OpEdTeaser
            data={item}
            biAction={setBiAction(idx, item, biAction)}
            isLazyloadImages={isLazyloadImages}
            isStackedAboveMobile={false}
            theme={theme}
            miscStyles={{
              gridColumn: col,
            }}
          />
        );
      })
      }
    </Paginator>
  );
}
