// @flow
import * as React from 'react';
import { type StyleProps, } from '@haaretz/htz-css-tools';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

import getImageAssets from '../../../../utils/getImageAssets';
import Image from '../../../Image/Image';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';

type TateTeaserPropsType = {
  index: number,
  isLazyloadImages: boolean,
  miscStyles?: ?StyleProps,
  biAction: ?() => void,
  data: TeaserDataType,
  theme: Object,
};

TateLabelsTeaser.defaultProps = {
  miscStyles: null,
};

const teaserMiscStyles = (isFirst, miscStyles, index, theme) => ({
  gridColumnStart: [ { from: 'l', value: index % 2 === 0 ? 2 : 3, }, ],
  paddingInlineEnd: isFirst ? '1rem' : '4rem',
  paddingTop: theme.tateStyle.teaser.paddingTop(isFirst),
  paddingBottom: theme.tateStyle.teaser.paddingBottom(isFirst),
  outline: `1px solid ${theme.color('neutral', '-5')}`,
  backgroundColor: theme.color('neutral', '-10'),
  fontFamily: theme.fontStacks.commercial,
  ...(miscStyles || {}),
});

export default function TateLabelsTeaser({
  index,
  data,
  isLazyloadImages,
  miscStyles,
  biAction,
  theme,
}: TateTeaserPropsType) {
  const { teaser, } = theme.tateStyle;
  const isFirst = index === 0;
  const defaultTeaserColTemplate = '0 1fr 0';
  const defaultTeaserRowTemplate = 'auto';
  const defaultTeaserAreaTemplate = `
    ". content ."
    ". . ."
  `;

  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      areasTemplate={
        isFirst
          ? [
            {
              until: 's',
              value: `
            "media . ."
            "media content ."
            "media . ."
            `,
            },
            { from: 's', value: defaultTeaserAreaTemplate, },
          ]
          : defaultTeaserAreaTemplate
      }
      colTemplate={
        isFirst
          ? [ { until: 's', value: '3fr 4fr 0', }, { from: 's', value: defaultTeaserColTemplate, }, ]
          : defaultTeaserColTemplate
      }
      rowTemplate={
        isFirst
          ? [
            { until: 's', value: '1rem auto 1fr 0', },
            { from: 's', value: defaultTeaserRowTemplate, },
          ]
          : defaultTeaserRowTemplate
      }
      key={data.contentId}
      colGap={teaser.colGap}
      rowGap={isFirst ? null : teaser.rowGap}
      miscStyles={teaserMiscStyles(isFirst, miscStyles, index, theme)}
    >
      {isFirst ? (
        <TeaserMedia data={data} miscStyles={{ display: [ { from: 's', value: 'none', }, ], }}>
          <Image
            image={image}
            lazyLoad={isLazyloadImages}
            imgOptions={getImageAssets({
              bps: theme.bps,
              aspect: 'regular',
              sizes: '240px',
              widths: [ 240, 480, 720, 1080, ],
            })}
            onClick={biAction}
          />
        </TeaserMedia>
      ) : null}
      <TeaserHeader
        isGridItem
        {...data}
        typeScale={[
          { until: 's', value: 0, },
          { from: 's', until: 'l', value: 1, },
          { from: 'xl', value: -1, },
        ]}
        onClick={biAction}
        miscStyles={{ marginTop: [ { until: 's', value: '4rem', }, ], }}
      />

    </Teaser>
  );
}
