import React from 'react';
import useGetComponent from '../../hooks/GetComponentContext/useGetComponent';
import { flatBody, } from '../../hooks/Page/useArticleBodyData';

export default function RichText({ items, ...props }) {
  const getComponent = useGetComponent();

  const flattedItems = flatBody(items);

  return (flattedItems || []).map((element, i) => {
    const Element = getComponent(element.kind || element.inputTemplate, {
      ...element,
      loadPriority: element.loadPriority,
      isExpanded: element.isExpanded,
      preventRender: element.preventRender,
    });

    return (
      <Element
        {...element}
        {...props}
        key={element.contentId || String(i)}
      />
    );
  });
}
