// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { useInView, } from 'react-intersection-observer';
import HtzLink from '../../../HtzLink/HtzLink';
import Image from '../../../Image/Image';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserContent from '../../../TeaserContent/NewTeaserContent';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserReadingTime from '../../../TeaserReadingTime/TeaserReadingTime';
import TeaserReadingArticleCount from '../../../TeaserReadingArticleCount/TeaserReadingArticleCount';
import IconCheck from '../../../Icon/icons/IconCheck';
import getTeaserHref from '../../../../utils/getTeaserHref';

import getImageAssets from '../../../../utils/getImageAssets';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import checkSiteFromConfig from '../../../../utils/checkSiteFromConfig';

const { isHDC, } = checkSiteFromConfig();

type PropsType = {
    markedAsRead?: boolean,
    isAnimated?: boolean,
    item: TeaserDataType,
    biAction: ?() => void,
};

function teaserMiscStyle({ theme, markedAsRead, inView, isAnimated, }) {
  return {
    ...(inView || !isAnimated
      ? {
        opacity: 1,
        transform: 'translateY(0)',
      }
      : {
        opacity: 0.05,
        transform: 'translateY(25%)',
      }),
    transition: 'all 0.5s linear',
    gridTemplateAreas: markedAsRead
      ? [ { until: 's', value: '"content"', }, { from: 's', value: '"media" "content"', }, ]
      : '"media" "content"',
  };
}

function teaserMediaMiscStyle({ theme, markedAsRead, }) {
  return {
    display: markedAsRead ? [ { until: 's', value: 'none', }, ] : null,
  };
}

function teaserImageOverlay({ theme, }) {
  return {
    ':before': {
      content: "''",
      position: 'absolute',
      zIndex: theme.getZIndex('above'),
      width: '100%',
      height: '100%',
      backgroundImage: 'linear-gradient(to top, rgba(0,0,0,.9), rgba(0,0,0,.5) 25%, rgba(0,0,0,0))',
    },
  };
}

function teaserContentMiscStyle({ theme, markedAsRead, }) {
  return {
    color: theme.color('white'),
    backgroundColor: markedAsRead ? [ { until: 's', value: theme.color('neutral', '-1'), }, ] : null,
    position: markedAsRead ? [ { from: 's', value: 'absolute', }, ] : 'absolute',
    zIndex: theme.getZIndex('above'),
    bottom: 0,
    left: 0,
    width: '100%',
    paddingLeft: [
      { until: 'xl', value: '4rem', },
      { from: 'xl', value: '5rem', },
    ],
    paddingRight: [
      { until: 'xl', value: '4rem', },
      { from: 'xl', value: '5rem', },
    ],
    paddingBottom: '2rem',
    paddingTop: [ { until: 's', value: '1rem', }, { from: 's', value: '2rem', }, ],
    type: [
      { until: 's', value: 2, },
      { from: 's', until: 'xl', value: 0, },
      { from: 'xl', value: 1, },
    ],
  };
}

NilsTeaser.defaultProps = {
  markedAsRead: false,
  isAnimated: false,
};

export default function NilsTeaser({ item, biAction, markedAsRead, isAnimated, }: PropsType) {
  const { theme, css, } = useFela();
  const [ ref, inView, ] = useInView({ triggerOnce: true, threshold: 0.25, });
  const image = item?.mobileImage || item?.image;

  return (
    <Teaser attrs={{ id: `nils-${item.contentId}`, }} miscStyles={teaserMiscStyle({ theme, markedAsRead, inView, isAnimated, })} fwRef={ref}>
      <TeaserMedia
        data={item}
        onClick={biAction}
        miscStyles={teaserMediaMiscStyle({ theme, markedAsRead, })}
      >
        <div className={css(teaserImageOverlay)}>
          <Image
            image={image}
            imgOptions={getImageAssets({
              bps: theme.bps,
              aspect: 'square',
              sizes: [
                { from: 'xl', size: '385px', },
                { from: 'l', size: '310', },
                { from: 'm', size: '350px', },
                { from: 's', size: '264px', },
                { size: 'calc(100vw -4rem)', },
              ],
              widths: [ 1080, 720, 385, 310, 270, ],
            })}
          />
        </div>
      </TeaserMedia>
      <TeaserContent tagName="header" isCenteredContent miscStyles={teaserContentMiscStyle({ theme, markedAsRead, })}>
        <TeaserHeader
          miscStyles={{
            fontWeight: 400,
          }}
          isCentered
          {...item}
        />
        {isHDC ? (
          <TeaserReadingArticleCount
            articleCount={item.articleCount}
            miscStyles={{
              marginTop: '1rem',
              display: 'block',
              type: [ { until: 'xl', value: -1, }, { from: 'xl', value: -2, }, ],
            }}
          />
        ) : (
          <TeaserReadingTime
            articleWordCount={item.wordCount}
            miscStyles={{
              marginTop: '1rem',
              display: 'block',
              type: [ { until: 'xl', value: -1, }, { from: 'xl', value: -2, }, ],
            }}
            iconSize={2}
          />
        )}
      </TeaserContent>
      { markedAsRead ? <MarkedAsRead data={item} /> : null }
    </Teaser>
  );
}

function markedAsReadStyles({ theme, }) {
  return {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.color('quaternary'),
    top: 0,
    left: 0,
    paddingTop: [ { until: 's', value: '1rem', }, { from: 's', value: '2rem', }, ],
    paddingBottom: [ { until: 's', value: '1rem', }, { from: 's', value: '2rem', }, ],
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(235, 235, 235, 0.8)', // theme.color('neutral', '-6'),
    zIndex: theme.getZIndex('above'),
  };
}

type MarkedAsReadPropsType = {
  data: TeaserDataType,
  onClick: ?() => void,
}
MarkedAsRead.defaultProps = {
  onClick: null,
};
function MarkedAsRead({ data, onClick, }: MarkedAsReadPropsType) {
  const { css, } = useFela();

  return (
    <HtzLink
      onClick={onClick}
      href={getTeaserHref(data)}
      target={data.linkTarget}
      attrs={{ tabIndex: -1, }}
    >
      <div className={css(markedAsReadStyles)}>
        <IconCheck size={[ { until: 's', value: 10, }, { from: 's', value: 13, }, ]} />
      </div>
    </HtzLink>
  );
}
