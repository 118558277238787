// @flow
import * as React from 'react';
import ApolloConsumer from '../../ApolloBoundary/ApolloConsumer';
import UserDispenser from '../../User/UserDispenser';
import FILTER_OUT_UNREAD_ARTICLES from '../queries/filterOutUnreadArticles.graphql';

type FilteredArticlesType = {
    readArticles: Array<string>,
}

type PropsType = {
    articleIds: Array<string>,
    children: ({ filterOutUnreadArticles: FilteredArticlesType, }) => React.Node,
}

export default function FilterUnreadArticles({ articleIds, children, }: PropsType) {
  return (
    <UserDispenser render={({ user, }) => (
      <ApolloConsumer>
        {client => (
          <FilterUnreadArticlesInner articleIds={articleIds} user={user} client={client}>
            {({ filterOutUnreadArticles, }) => children({ filterOutUnreadArticles, })}
          </FilterUnreadArticlesInner>
        )}
      </ApolloConsumer>
    )}
    />
  );
}

function FilterUnreadArticlesInner({ articleIds, user, client, children, }) {
  const [ filterOutUnreadArticles, setFilterOutUnreadArticles, ] = React.useState({ readArticles: [], });

  React.useEffect(() => {
    const { type, } = user;
    if (type === 'paying') {
      client.query({
        query: FILTER_OUT_UNREAD_ARTICLES,
        variables: { input: { articleIds, }, },
      })
        .then(({ data, }) => {
          if (data) {
            setFilterOutUnreadArticles(data.filterOutUnreadArticles);
          }
        });
    }
  }, [ client, articleIds, user, ]);

  return children({ filterOutUnreadArticles, });
}
