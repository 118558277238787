// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { useRouter, } from 'next/router';
import EventTracker from '../../utils/EventTracker';
import H from '../AutoLevels/H';
import HtzLink from '../HtzLink/HtzLink';
import RichText from '../RichText/RichText';
import Image from '../Image/Image';
import useGetComponent from '../../hooks/GetComponentContext/useGetComponent';
import type { ImageDataType, } from '../../flowTypes/ImageDataType.js';

type Props = {
  title: string,
  htmlContent: Array<Object>,
  image: ImageDataType,
};

function wrapperStyle({ theme, hasImage, }) {
  return {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...(hasImage
      ? { padding: '6rem', }
      : { padding: '15rem 6rem 6rem 6rem', }),
  };
}

function titleStyle({ theme, }) {
  return {
    marginBottom: '3rem',
    extend: [
      theme.type(4),
      theme.mq({ until: 's', }, { maxWidth: '38rem', }),
    ],
  };
}

function NewsletterServiceMessage({ biAction, title, htmlContent, image, }: Props & { biAction: Object => void, }) {
  const { css, theme, } = useFela({ hasImage: !!image, });
  const getComponent = useGetComponent();
  const router = useRouter();
  const searchStart = router.asPath.indexOf('?');
  const search = searchStart > -1 ? router.asPath.substring(searchStart) : '';
  const params = new URLSearchParams(search);
  const templateRx = /\{\{(\w+)\}}/g;

  const templateFunc = text => {
    const replacer = (match, g1, g2) => params.get(g1) || '';

    const newText = text.replace(templateRx, replacer);
    return newText;
  };

  const Logo = getComponent('siteIconTransparent');

  React.useEffect(() => {
    const email = params.get('email');
    const resourceId = params.get('resourceId');
    const resourceName = params.get('resourceName');
    const feature = params.get('feature');


    if (email && resourceId && feature) {
      biAction({
        actionCode: 9,
        feature,
        featureType: 'Content',
        newsletterEmail: email,
        segmentName: resourceName,
        segmentId: resourceId,
      });
    }
  }, [ biAction, params, ]);

  return (
    <div className={css(wrapperStyle)}>
      { image
        ? (
          <Image
            hasWrapper={false}
            image={image}
            imgOptions={{
              transforms: {
                width: '300',
                aspect: 'square',
                quality: 'auto',
              },
            }}
          />
        ) : null }
      <Logo size={9} color={theme.newsletterStyle.service.logoColor} miscStyles={{ marginBottom: '3rem', }} />
      <H className={css(titleStyle)}>{title}</H>
      <RichText items={htmlContent} templateFunc={templateFunc} />
      <div className={css({
        color: theme.color(...theme.newsletterStyle.service.btnBackColor),
      })}
      >
        <HtzLink
          className={css({
            marginTop: '3rem',
            color: theme.color('primary'),
            border: '1px solid',
            display: 'block',
            padding: '1rem 3rem',
            fontWeight: 700,
          })}
          href={theme.errorPageI18n.error404.button.href}
        >
          {theme.errorPageI18n.error404.button.text}
        </HtzLink>
      </div>
    </div>
  );
}

export default function (props: Props) {
  return (
    <EventTracker>
      {({ biAction, }) => (
        <NewsletterServiceMessage {...props} biAction={biAction} />
      )}
    </EventTracker>
  );
}
