// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import Picture from '../../../Image/Picture';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import getPictureAssets from '../../../../utils/getPictureAssets';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';

import { type ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import { type ListDataType, } from '../../../../flowTypes/ListDataType';
import type { ListBiImpressionType, } from '../../../../flowTypes/ListBiImpressionType';

import useOneTime from '../../../../hooks/useOneTime';

type Props = {
  list: ListDataType,
  biAction: ?ListBiActionType,
  biImpression: ?ListBiImpressionType,
  isLazyloadImages: boolean,
};

const areasTemplate = [
  {
    from: 'l',
    value: `
    "footer"
    "content"
    "."
    "."
    "media"
    `,
  },
  {
    from: 's',
    until: 'l',
    value: `
    ".     footer    ."
    ".     content   ."
    ".      .        ."
    ".      .        ."
    "media media media"
    `,
  },
  {
    until: 's',
    value: `
    "media media media"
    ".      .        ."
    ".      .        ."
    ".     footer    ."
    ".     content   ."
    ".      .        ."
    ".      .        ."
    `,
  },
];

function Clamps({
  isLazyloadImages,
  list: {
    items: [ item, ],
  },
  biAction: rawBiAction,
  biImpression,
}: Props): React.Node {
  const { theme, } = useFela();
  const biAction = setBiAction(0, item, rawBiAction);

  useOneTime(item && !!biImpression && typeof biImpression === 'function', setBiImpression(0, item, biImpression));

  const image = item?.mobileImage || item?.image;

  return (
    <Teaser
      areasTemplate={areasTemplate}
      rowGap="1rem"
      colGap={[
        { until: 's', value: '2rem', },
        { from: 's', until: 'l', value: '4rem', },
      ]}
      colTemplate={[ { until: 'l', value: '0 1fr 0', }, ]}
      gridGap={null}
      miscStyles={{
        gridColumnEnd: [
          { from: 'l', value: 'span 2', },
        ],
        width: [ { until: 'l', value: 'auto', }, ],
        marginStart: [
          { until: 's', value: '-2rem', },
          { from: 's', until: 'l', value: '-4rem', },
        ],
        marginEnd: [
          { until: 's', value: '-2rem', },
          { from: 's', until: 'l', value: '-4rem', },
        ],
        paddingTop: [
          { from: 's', until: 'l', value: '4rem', },
        ],
      }}
      attrs={{
        'data-test': 'clamps',
      }}
    >
      <TeaserHeader
        isGridItem
        isCentered
        isH1
        typeScale={[
          { from: 'l', value: 8, },
          { from: 's', until: 'l', value: 6, },
          { until: 's', value: 3, },
        ]}
        miscStyles={{
          color: theme.color('neutral'),
          fontWeight: 'normal',
        }}
        onClick={biAction}
        {...item}
      />
      <TeaserMedia data={item} onClick={biAction}>
        <Picture
          lazyLoad={isLazyloadImages}
          {...getPictureAssets({
            imgData: image,
            bps: theme.bps,
            defaultImgOptions: {
              aspect: 'square',
              sizes: '100vw',
              widths: [ 375, 469, 556, 604, 720, ],
            },
            sources: [
              {
                from: 's',
                aspect: 'landscape',
                sizes: [
                  { from: 'xl', size: '1207px', },
                  { from: 'l', size: '976px', },
                  { from: 'm', size: '720px', },
                  { from: 's', size: '552px', },
                ],
                widths: [ 1207, 976, 720, 552, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserFooter
        showAuthor
        showTime={false}
        data={item}
        typeScale={[
          { from: 'xl', value: 0, },
          { from: 's', until: 'xl', value: 1, },
          { until: 's', value: -1, },
        ]}
        miscStyles={{
          color: theme.color('primary', '+1'),
          textAlign: 'center',
        }}
      />
    </Teaser>
  );
}

export default Clamps;
