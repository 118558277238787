import dynamic from 'next/dynamic';

import * as React from 'react';
import { useFela, } from 'react-fela';
import {
  AlgoliaList,
  // AlgoliaInstantSearchUrl,
  BreakingNewsBox,
  Abner,
  // Aharoni,
  Amy,
  // Beavis,
  Bender,
  BenderBig,
  // Bilingual,
  // Boxy,
  // BoxyInverse,
  BoxyInverseHdc,
  Butthead,
  BoxyInverseLabels,
  // Calculon,
  // Clampazzo,
  Clamps,
  // Crushinator,
  // ClickTrackerWrapper,
  Conrad,
  // Donatello,
  Ethan,
  Donbot,
  // ElementGroup,
  Error,
  HdcFooter,
  // Flexo,
  Gamal,
  AdSlot,
  AdSlotPrioritySelector,
  GridContainer,
  // GridElement,
  Elzar,
  Hawking,
  HtmlElement,
  HtmlNode,
  // HyperChicken,
  // Iframe,
  Interactive,
  // Kroker,
  // Leonardo,
  List,
  MainBlock,
  Marco,
  Masthead,
  // Michelangelo,
  MobileListWrapper,
  // Mom,
  // Morbo,
  Mousepad,
  OutbrainSectionPage,
  Muli,
  // Nibbler,
  Nils,
  // OutbrainHomepage,
  Ogi,
  // Panucci,
  // Paragraph,
  Pazuzu,
  PazuzuLabels,
  Roberto,
  // RssFeed,
  Santa,
  Schoonhoven,
  // Slim,
  Shushu,
  // Slugs,
  // Spawn,
  Tate,
  TateLabels,
  // TabElement,
  // TopNews,
  // Vogel,
  // Weekend,
  WidePageClickTracker,
  WidePageLayoutImage,
  Wong,
  Zapp,
  ZappLabels,
  // Zombie,
  LayoutContainer,
  SectionPageTitle,
  SectionArticle,
  SantaLabels,
  NewsletterServiceMessage,

  // icons
  IconHaaretzComLogoFull,
  IconHaaretzComLabel,
  IconAlefLogoTransparent,
  IconHtzLoader,

  // utils
  getElementsFactory,
} from '@haaretz/htz-components';

const ImageGalleryTeaser = dynamic(() => import('../components/ImageGalleryTeaser/ImageGalleryteaser'));

function MastheadLogo() {
  const { theme, } = useFela();
  return (
    <IconHaaretzComLogoFull
      attrs={{ 'aria-hidden': true, }}
      size={theme.mastheadStyle.articlePageLogoSize}
      color={[ 'primary', 'base', ]}
      secondaryColor={theme.color('primary', '-2')}
      miscStyles={{
        display: 'block',
        marginTop: [ { until: 's', value: '1rem', }, ],
        marginBottom: [ { until: 's', value: '1rem', }, ],
      }}
    />
  );
}

// eslint-disable-next-line react/prop-types
function MastheadLabelLogo() {
  const { theme, } = useFela();
  return (
    <IconHaaretzComLabel
      attrs={{ 'aria-hidden': true, }}
      size={4.5}
      fill={[ 'neutral', 'base', ]}
      miscStyles={{
        display: 'block',
      }}
    />
  );
}
// eslint-disable-next-line react/prop-types
function HdcHomePageBreakingNews({ properties, ...props }) {
  return (
    <LayoutContainer miscStyles={{
      marginTop: [ { from: 's', value: '8rem', }, ],
      padding: [ { from: 's', value: '1rem', }, ],
    }}
    >
      <BreakingNewsBox {...properties} {...props} />
    </LayoutContainer>
  );
}

const elements = new Map([
  // General components
  // // [ 'com.tm.element.AlgoliaIntantSearch', AlgoliaInstantSearchUrl, ],
  // [ 'com.tm.BreakingNewsBox', HdcHomePageBreakingNews, ],
  [ 'PageNavigation', Masthead, ],
  [ 'MainBlock', props => <MainBlock {...props} viewProps={{ numberOfRelatedArticles: 2, }} />, ],
  // [ 'com.tm.element.SearchResultsList', AlgoliaList, ],
  // // [ 'com.polobase.ClickTrackerBannersWrapper', ClickTrackerWrapper, ],
  [ 'AdSlot', AdSlot, ],
  [ 'AdSlotPrioritySelector', AdSlotPrioritySelector, ],
  [ 'AdSlotRichTextElement', AdSlot, ],
  [ 'Outbrain', OutbrainSectionPage, ],
  // [ 'com.polobase.whtzMobileSiteListsWrapper', MobileListWrapper, ],
  // // [ 'com.tm.ElementGroup', ElementGroup, ],
  // // [ 'com.tm.ExternalRssElement', RssFeed, ],
  [ 'PageFooter', HdcFooter, ],
  // // [ 'com.tm.GridElementGroup', GridElement, ],
  // [ 'com.tm.GridContainer', GridContainer, ],
  // // [ 'com.tm.HeaderNewsGroup', TopNews, ],
  // [ 'interactive', Interactive, ],
  // [ 'com.tm.ArticleInteractiveHtmlElement', Interactive, ],
  [ 'HtmlElement', HtmlElement, ],
  [ 'StaticElement', HtmlElement, ],
  [ 'image', WidePageLayoutImage, ],
  [ 'htz_image_Image', WidePageLayoutImage, ],
  // // [ 'com.tm.IframElement', Iframe, ],
  // [ 'com.tm.ResubscribeElementsGroup', ],
  // // [ 'com.tm.TabViewElement', TabElement, ],
  [ 'htz_list_List', List, ],
  // [ 'com.tm.PageTitle', SectionPageTitle, ],
  // [ 'com.htz.SectionArticle', SectionArticle, ],
  [ 'NewsletterPageMessage', NewsletterServiceMessage, ],
  [ 'HtmlNode', HtmlNode, ],
  [ 'error', Error, ],
  [ 'imageGalleryTeaser', ImageGalleryTeaser, ],

  // list views
  [ 'Abner', Abner, ],
  [ 'Amy', Amy, ],
  // [ 'Beavis', Beavis, ],
  [ 'Bender', Bender, ],
  [ 'BenderBig', BenderBig, ],
  // [ 'Boxy', props => <Boxy {...props} viewProps={{ hasMarginTop: true, }} />, ],
  // [
  //   'BoxyInverse',
  //   props => (
  //     // eslint-disable-next-line react/prop-types
  //     <BoxyInverse {...props} viewProps={{ ...(props.viewProps || {}), hasMarginTop: true, }} />
  //   ),
  // ],
  [ 'Boxyinverse_HDC', BoxyInverseHdc, ],
  [ 'BoxyInverse_Labels', BoxyInverseLabels, ],
  [ 'Schoonhoven_Labels', props => <Schoonhoven {...props} viewProps={{ ...(props.viewProps || {}), isCommercial: true, }} />, ],
  [ 'Butthead', Butthead, ],
  // [ 'Calculon', Calculon, ],
  // [ 'Crushinator', Crushinator, ],
  // [ 'Clampazzo', Clampazzo, ],
  [ 'Clamps', Clamps, ],
  [ 'Conrad', Conrad, ],
  // [ 'Donatello', Donatello, ],
  // [ 'Debate1', Bilingual, ],
  // [ 'Debate2', Bilingual, ],
  [ 'Donbot', Donbot, ],
  [ 'Elzar', Elzar, ],
  [ 'Ethan', Ethan, ],
  [ 'Hawking', Hawking, ],
  // [ 'HyperChicken', HyperChicken, ],
  // [ 'Flexo', Flexo, ],
  [ 'Gamal', Gamal, ],
  // [ 'Kroker', Kroker, ],
  // [ 'Leonardo', Leonardo, ],
  // [ 'Michelangelo', Michelangelo, ],
  // [ 'Mom', Mom, ],
  [ 'Mousepad', Mousepad, ],
  // [ 'Morbo', Morbo, ],
  // [ 'Nibbler', Nibbler, ],
  [ 'Nils_HDC', Nils, ],
  [ 'Ogi_HDC', Ogi, ],
  [ 'Muli_HDC', Muli, ],
  [ 'Shushu_HDC', Shushu, ],
  // [ 'Panucci', Panucci, ],
  [ 'Pazuzu', Pazuzu, ],
  [ 'Pazuzu_Labels', PazuzuLabels, ],
  [ 'Roberto', Roberto, ],
  [ 'Santa', Santa, ],
  [ 'Santa_Labels', SantaLabels, ],
  // [ 'Slim', Slim, ],
  [ 'Tate', Tate, ],
  [ 'Tate_Labels', TateLabels, ],
  // [ 'Slugs', Slugs, ],
  // [ 'Spawn', Spawn, ],
  // [ 'Spawn', Spawn, ],
  // [ 'Weekend', Weekend, ],
  [ 'Wong', Wong, ],
  [ 'Zapp', Zapp, ],
  [ 'Zapp_Labels', ZappLabels, ],
  [ 'Schoonhoven', Schoonhoven, ],
  // [ 'Zombie', Zombie, ],
  // [ 'Vogel', Vogel, ],

  // Algolia list-views
  [ 'Marco', Marco, ],
  // [ 'Aharoni', Aharoni, ],
  [ 'mastheadLogo', MastheadLogo, ],
  [ 'mastheadLabelLogo', MastheadLabelLogo, ],
  [ 'siteLogo', IconHaaretzComLogoFull, ],
  [ 'siteIconTransparent', IconAlefLogoTransparent, ],

  /* SectionArticle components */
  // [ 'p', Paragraph, ],
  // [ 'h3', Paragraph, ],
  // [ 'a', Paragraph, ],
  // [ 'ul', Paragraph, ],
  // [ 'ol', Paragraph, ],
  // [ 'li', Paragraph, ],

  // icons
  [ 'loaderIcon', IconHtzLoader, ],
]);

const getSectionPageElements = getElementsFactory(elements);

export default getSectionPageElements;
