// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import type { Node, } from 'react';

import List from '../List/List';
import ListView from '../ListView/NewListView';
import AdSlot from '../AdManager/AdSlot';
import useGetComponent from '../../hooks/GetComponentContext/useGetComponent';
import { isCountdown, isIframe, } from '../../utils/validateType';

import type { ListDataType, } from '../../flowTypes/ListDataType';
import type { AdSlotType, } from '../../flowTypes/AdSlotType';
import type { CountdownType, } from '../../flowTypes/CountdownType';
import type { HtmlElementType, } from '../../flowTypes/HtmlElementType';
import type { Interactive, } from '../../flowTypes/Interactive';
import type { IframeType, } from '../../flowTypes/IframeType';

type Props = {
  slotA: ListDataType,
  slotB: AdSlotType,
  slotC: ListDataType,
  mainBlockComponents: Array<?CountdownType | ?HtmlElementType | ?IframeType | ?Interactive>,
  viewProps?: Object,
};

MainBlock.defaultProps = {
  viewProps: null,
};

export default function MainBlock({
  slotA,
  slotB,
  slotC,
  mainBlockComponents,
  viewProps,
}: Props): Node {
  const getComponent = useGetComponent();
  const { css, } = useFela();

  const slotAView = slotA?.[0]?.view;
  const isWideMain = [ 'Clamps', 'Conrad', 'Corona', 'Roberto', 'BoxyInverse', 'ConradDark', 'CoronaDark', ].includes(slotAView);
  const supportsIframe = slotAView === 'Roberto';
  const colTemplate = [
    { from: 'l', until: isWideMain ? undefined : 'xl', value: '2fr 1fr', },
  ];
  if (!isWideMain) colTemplate.push({ from: 'xl', value: '7fr 3fr', });
  const countdownIndex = mainBlockComponents.findIndex(isCountdown);

  // $FlowFixMe
  const countdownObj: ?CountdownType = isCountdown(mainBlockComponents[countdownIndex])
    ? mainBlockComponents[countdownIndex]
    : null;

  // $FlowFixMe
  const iframes: ?IframeType[] = supportsIframe ? mainBlockComponents.filter(isIframe) : null;

  const htmlElements = mainBlockComponents.filter(isAnHtmlElement)
    .map(htmlElement => ({
      htmlElement,
      // $FlowFixMe
      HtmlElement: getComponent(htmlElement.inputTemplate),
    }));
  const hasHtmlElements = htmlElements.length > 0;

  const numberOfRelatedArticles = (viewProps && viewProps.numberOfRelatedArticles) || 3;

  return (
    <ListView
      colTemplate={colTemplate}
      colGap={
        isWideMain
          ? '0'
          : [ { until: 'xl', value: '0', }, { from: 'xl', value: '2rem', }, ]
      }
      rowGap={[ { until: 's', value: '2rem', }, { from: 's', value: '4rem', }, ]}
      gridGap={null}
      innerBackgroundColor={
        supportsIframe
          ? 'white'
          : [
            { until: 's', value: 'transparent', },
            { from: 's', value: 'white', },
          ]
      }
      padding={[
        { until: 's', value: '0 2rem', },
        { from: 's', value: '4rem 4rem 4rem 4rem', },
      ]}
      marginTop={0}
      attrs={{
        'data-test': 'mainBlock',
        'data-main': 'mainBlock',
      }}
    >
      <List
        {...slotA[0]}
        viewProps={{
          countdownObj,
          iframes,
          inMainBlock: true,
          numberOfRelatedArticles,
        }}
      />
      <AdSlot
        {...slotB[0]}
        wrapperMiscStyles={{
          order: [
            ...(hasHtmlElements ? [ { until: 'l', value: 5, }, ] : []),
            { from: 'l', until: isWideMain ? undefined : 'xl', value: 5, },
          ],
          justifySelf: [ { from: 'l', until: 'xl', value: 'end', }, ],
          alignSelf: [ { from: 'xl', value: isWideMain ? 'auto' : 'center', }, ],
          display: [ { until: 's', value: 'none', }, ],
        }}
      />
      {hasHtmlElements ? (
        <div className={css({ gridColumn: '1/-1', })}>
          {htmlElements.map(({ htmlElement, HtmlElement, }) => (
            <HtmlElement
              // $FlowFixMe
              key={htmlElement.contentId}
              {...htmlElement}
            />
          ))}
        </div>
      ) : null}
      <List {...slotC[0]} viewProps={{ isWideMain, }} />
    </ListView>
  );
}

function isAnHtmlElement(candidate) {
  return candidate
    ? candidate.inputTemplate === 'com.tm.HtmlElement' || candidate.inputTemplate === 'com.tm.ArticleInteractiveHtmlElement'
    : false;
}
