// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import ListView from '../../../ListView/NewListView';
import ZappLabelsItem from './ZappLabelsItem';
import setBiAction from '../../../../utils/setBiAction';

const teasers = [ ZappLabelsItem, ZappLabelsItem, ZappLabelsItem, ZappLabelsItem, ];

ZappLabelsView.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: false,
};
export default function ZappLabelsView({
  isLazyloadImages,
  list,
  biAction,
}: ListViewProps): React.Node {
  const { theme, } = useFela();
  const { items, } = list || {};

  if (!items || !items.length) return null;

  function itemRenderer(data, i) {
    if (data && teasers[i]) {
      return teasers[i]({
        data,
        biAction: setBiAction(i, data, biAction),
        hideImageOnMobile: false,
        isLazyloadImages,
        theme,
      });
    }
    return null;
  }
  const itemsToRender = items.slice(0, 4);

  return (
    <ListView
      innerBackgroundColor="transparent"
      marginTop={0}
      padding={[ { until: 's', value: '2rem', }, { from: 's', value: '2.5rem 0rem 4rem', }, ]}
      colTemplate={[ { from: 'l', value: 'repeat(4, 1fr)', }, ]}
      attrs={{
        'data-test': 'zappLabels',
      }}
    >
      {itemsToRender.map(itemRenderer)}
    </ListView>
  );
}
