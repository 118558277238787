// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { type StyleProps, parseStyleProps, } from '@haaretz/htz-css-tools';
import Picture from '../Image/Picture';
import getPictureAssets from '../../utils/getPictureAssets';
import IconClock2 from '../Icon/icons/IconClock2';
import H from '../AutoLevels/H';
import ProgressBar from '../ProgressBar/ProgressBar';
import ProgressBarHDC from '../ProgressBar/ProgressBarHDC';

import { toHumanTimeString, } from '../../utils/time';
import { calcReadingTime, calcReadingProgress, } from '../../utils/calcReadingTime';
import { type ImageDataType, } from '../../flowTypes/ImageDataType';
import { type TeaserDataType, } from '../../flowTypes/TeaserDataType';
import checkSiteFromConfig from '../../utils/checkSiteFromConfig';

const { isHDC, } = checkSiteFromConfig();

const titleFontSize = isHDC ? 6 : 9;

type PropsType = {
  /** The List Title. */
  title: ?string | ?Array<string>,
  /**
   * description of the list to display
   */
  description: ?string,
  /**
   * List items
   */
  items: Array<TeaserDataType>,
  /**
   * Background color thats compatible with list image
   */
  imageBg: ?string,
  /** The list image */
  image: ?ImageDataType,
  /**
   * A special property applying miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: ?StyleProps,
  /**
   * A special property applying miscellaneous CSS values that
   * trump all default values of the tile. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  titleMiscStyles: ?StyleProps,
  /**
   * Array of content-ids of articles that are already read by user.
   */
  readArticles?: Array<string>,
  /**
   * onClick handler when user clicks the header.
   */
  onClick: () => void,
};

const progressBoxShadow = '0 2px 6px 0 rgba(0, 0, 0, 0.1)';

function wrapperStyle({ theme, miscStyles, listImageBg, }) {
  return {
    backgroundColor: listImageBg,
    display: 'grid',
    gridGap: '2rem',
    extend: [
      theme.mq({ until: 's', }, { gridTemplateRows: '1fr auto', }),
      theme.mq({ from: 's', until: 'xl', }, { gridTemplateColumns: '1fr 1fr', }),
      theme.mq({ from: 'xl', }, { gridTemplateColumns: '4fr 3fr', }),

      ...(miscStyles
        ? parseStyleProps(miscStyles, theme.mq, theme.type)
        : []),
    ],
  };
}

function titleStyle({ theme, titleMiscStyles, }) {
  return {
    fontWeight: isHDC ? 700 : 400,
    extend: [
      theme.type(titleFontSize, { untilBp: 's', }),
      theme.type(6, { fromBp: 's', untilBp: 'l', }),
      theme.type(titleFontSize, { fromBp: 'l', }),
      ...(titleMiscStyles
        ? parseStyleProps(titleMiscStyles, theme.mq, theme.type)
        : []),
    ],
  };
}

function descriptionStyle({ theme, titleMiscStyles, }) {
  return {
    marginTop: '3rem',
    fontWeight: 400,
    extend: [
      theme.type(1, { untilBp: 's', }),
      theme.type(-1, { fromBp: 's', untilBp: 'l', }),
      theme.type(1, { fromBp: 'l', untilBp: 'xl', }),
      theme.type(0, { fromBp: 'xl', }),
      ...(titleMiscStyles
        ? parseStyleProps(titleMiscStyles, theme.mq, theme.type)
        : []),
    ],
  };
}

function titleWrapperStyle({ theme, }) {
  return {
    maxWidth: '100rem',
    paddingBottom: 0,
    extend: [
      theme.mq({ until: 's', }, {
        padding: '3rem',
      }),
      theme.mq({ from: 's', until: 'l', }, {
        padding: '4rem',
      }),
      theme.mq({ from: 'l', }, {
        padding: '6rem',
      }),
    ],
  };
}

function titleInnerWrapperStyle({ theme, }) {
  return {
    maxWidth: '68rem',
  };
}

function remainedTextStyle({ theme, }) {
  return {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2rem',
    fontWeight: 700,
    extend: [
      theme.type(0, { untilBp: 'xl', }),
      theme.type(-1, { fromBp: 'xl', }),
    ],
  };
}

function progressBarStyle({ theme, }) {
  return {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: 'auto 1fr auto',
    ...(isHDC
      ? { alignItems: 'center', }
      : {}
    ),
    extend: [
      theme.mq({ until: 's', }, { marginTop: '3rem', }),
      theme.mq({ from: 's', until: 'l', }, { marginTop: '1rem', }),
      theme.mq({ from: 'l', until: 'xl', }, { marginTop: '3rem', }),
      theme.mq({ from: 'xl', }, { marginTop: '2rem', }),
      theme.type(0, { untilBp: 's', }),
      theme.type(-1, { fromBp: 's', untilBp: 'l', }),
      theme.type(0, { fromBp: 'l', untilBp: 'xl', }),
      theme.type(-1, { fromBp: 'xl', }),
    ],
  };
}

BingeListViewHeader.defaultProps = {
  imageBg: null,
  image: null,
  items: [],
  miscStyles: null,
  titleMiscStyles: null,
  readArticles: [],
};

export default function BingeListViewHeader({
  title,
  description,
  items, readArticles,
  imageBg: listImageBg,
  image: listImage,
  miscStyles,
  titleMiscStyles,
  onClick,
}: PropsType) {
  const { theme, css, } = useFela({ miscStyles, titleMiscStyles, listImageBg, });
  const { timeLeftToRead, articlesLeftToRead, } = theme.BingI18n || {};
  const readingProgress = calcReadingProgress(items, readArticles || []);
  const totalReadingTime = calcReadingTime(readingProgress.max);
  const totalReadingTimeMinutes = Math.round(totalReadingTime / 60000);
  const displayAccuracy = totalReadingTimeMinutes > 59 ? 'hours' : 'minutes';

  const progressReadingTime = calcReadingTime(readingProgress.value);
  const progressReadingTimeMinutes = Math.round(progressReadingTime / 60000);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <header className={css(wrapperStyle)} onClick={onClick}>
      <div className={css(titleWrapperStyle)}>
        <H className={css(titleStyle)}>{title}</H>
        <div className={css(titleInnerWrapperStyle)}>
          <p className={css(descriptionStyle)}>{description}</p>

          <div className={css(remainedTextStyle)}>
            {isHDC ? (
              <>
                {`${articlesLeftToRead} ${readArticles.length}/${items.length} `}
              </>
            ) : (
              <>
                <IconClock2
                  size={4}
                  miscStyles={{
                    paddingEnd: '1rem',
                    '> .hands': {
                      transition: '2s transform ease-in-out',
                      transformOrigin: 'center',
                      transform: progressReadingTimeMinutes > 0 ? 'rotate(calc(6 * 360deg))' : 'rotate(0)',
                    },
                  }}
                />
                {`${timeLeftToRead} ${toHumanTimeString((totalReadingTimeMinutes - progressReadingTimeMinutes) * 60000, 'minutes', displayAccuracy, theme.timeDuration)}`}
              </>
            )}
          </div>

          <div className={css(progressBarStyle)}>
            {isHDC ? undefined
              : (
                <span>{toHumanTimeString(progressReadingTimeMinutes * 60000, 'minutes', displayAccuracy, theme.timeDurationEmpty)}</span>
              )}
            {isHDC ? (
              <ProgressBarHDC
                totalArticles={items.length}
                articlesRead={readArticles.length}
                progressBarMiscStyles={{
                  overflow: 'visible',
                  alignSelf: 'center',
                  height: '1rem',
                }}
                progressBarValueMiscStyles={{
                  height: '200%',
                  transform: 'translateY(-25%)',
                }}
              />
            ) : (
              <ProgressBar
                min={0}
                max={isHDC ? items.length : totalReadingTimeMinutes}
                value={isHDC ? readArticles.length : progressReadingTimeMinutes}
                progressBarMiscStyles={{
                  overflow: 'visible',
                  alignSelf: 'center',
                  height: '1rem',
                  boxShadow: progressBoxShadow,
                  backgroundColor: theme.color('white'),
                }}
                progressBarValueMiscStyles={{
                  backgroundColor: theme.color('secondary', '+1'),
                  height: '200%',
                  transform: 'translateY(-25%)',
                  boxShadow: progressBoxShadow,
                }}
              />
            )}
            {isHDC ? undefined
              : (
                <span>{toHumanTimeString(totalReadingTimeMinutes * 60000, 'minutes', displayAccuracy, theme.timeDuration)}</span>
              )}
          </div>
        </div>
      </div>
      { listImage
        ? (
          <Picture
            bgcolor={[ 'transparent', ]}
            {...getPictureAssets({
              bps: theme.bps,
              imgData: listImage,
              defaultImgOptions: {
                sizes: [
                  { size: 'calc(100vw - 24px)', },
                ],
                aspect: 'square',
                widths: [ 320, 425, 604, ],
                positionInImgArray: 0,
              },
              sources: [
                {
                  positionInImgArray: 0,
                  aspect: 'regular',
                  from: 'l',
                  sizes: [
                    { from: 'xl', size: '602px', },
                    { from: 'l', size: '488px', },
                  ],
                  widths: [ 488, 602, ],
                },
                {
                  positionInImgArray: 0,
                  aspect: 'square',
                  from: 's',
                  sizes: [
                    { from: 'm', size: '360px', },
                    { from: 's', size: '276px', },
                  ],
                  widths: [ 276, 360, ],
                },
              ],
            })}
          />
        )
        : null }
    </header>
  );
}
