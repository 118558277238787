// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';

import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';

import getPictureAssets from '../../../../utils/getPictureAssets';

import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Picture from '../../../Image/Picture';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';

SantaLabelsView.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
};

const getAreasTemplate = listLength => {
  let untilLDynamicTemplate = '"t1 t2"';
  let fromLDynamicTemplate = '" he t1 t2"';
  for (let index = 2; index < listLength; index += 2) {
    untilLDynamicTemplate += `"t${index + 1} t${index + 2}"`;
    fromLDynamicTemplate += `"he t${index + 1} t${index + 2}"`;
  }

  return [
    {
      until: 'l',
      value: `
  "he he"
  "t1 t2"
  ${untilLDynamicTemplate}
  `,
    },
    {
      from: 'l',
      value: `
    "he t1 t2"
    ${fromLDynamicTemplate}
    `,
    },
  ];
};
const teaserProps = {
  areasTemplate: [
    {
      from: 'l',
      value: `
    "media . . ."
    "media . . ."
    "media . content ."
    "media . footer ."
    "media . . ."
    `,
    },
    {
      until: 'l',
      value: `
    "media media media"
    ". content ."
    ". footer ."
    ". . ."
    `,
    },
  ],
  colTemplate: [
    { from: 'xl', value: '3fr 0 2fr 0', },
    { from: 'l', until: 'xl', value: '2fr 0 3fr 0', },
    { until: 'l', value: ' 0 1fr 0', },
  ],
  rowTemplate: [ { from: 'l', value: '0 0 1fr auto 0', }, { until: 'l', value: 'auto 1fr auto 0', }, ],
  colGap: '1rem',
  rowGap: '1rem',
};

const pictureProps = {
  defaultImgOptions: {
    sizes: [
      { from: 'xl', size: '300px', },
      { from: 'l', size: '145px', },
      { from: 'm', size: '350px', },
      { from: 's', size: '260px', },
      { size: '285px', },
    ],
    aspect: 'regular',
    widths: [ 145, 300, 400, 600, 800, ],
  },
  sources: [
    {
      aspect: 'regular',
      until: 's',
      sizes: '285px',
      widths: [ 285, 600, 800, ],
    },
    {
      aspect: 'headline',
      from: 's',
      until: 'm',
      sizes: '260px',
      widths: [ 260, 350, 520, 700, ],
    },
    {
      aspect: 'headline',
      from: 'm',
      until: 'l',
      sizes: '350px',
      widths: [ 350, 450, 700, ],
    },
    {
      aspect: 'square',
      from: 'l',
      until: 'xl',
      sizes: '145px',
      widths: [ 145, 300, 450, ],
    },
    {
      aspect: 'regular',
      from: 'xl',
      sizes: '275px',
      widths: [ 275, 550, 1024, ],
    },
  ],
};

export default function SantaLabelsView({
  list,
  isLazyloadImages,
  biAction,
  gaAction,
}: ListViewProps): React.Node {
  const { dfp, items, clickTrackers, extraLinks, ...restOfList } = list || {};
  const { theme, } = useFela();

  return (
    <ListView
      areasTemplate={getAreasTemplate([ ...items, ...(dfp || []), ...(clickTrackers || []), ].length)}
      colTemplate={[ { until: 'l', value: '1fr 1fr', }, { from: 'l', value: '2fr 5fr 5fr', }, ]}
      padding={[ { until: 's', value: '0 2rem', }, { from: 's', value: '0', }, ]}
      rowGap={[ { until: 's', value: '2rem', }, ]}
      marginTop={[ { until: 's', value: 2.5, }, { from: 's', value: 4, }, ]}
      attrs={{
        'data-test': 'santaLabels',
      }}
    >
      <ListViewHeader
        {...restOfList}
        extraLinks={extraLinks?.length ? extraLinks.slice(0, 5) : null}
        biAction={biAction}
        isCommercial
        isSticky
      />
      {items.map((item, idx) => {
        const image = item?.mobileImage || item?.image;

        return (
          <Teaser
            gridArea={`t${idx + 1}`}
            {...teaserProps}
            key={item.contentId}
            miscStyles={{
              outline: `1px solid ${theme.color('neutral', '-5')}`,
              backgroundColor: theme.color('neutral', '-10'),
              fontFamily: theme.fontStacks.commercial,
            }}
          >
            <TeaserMedia data={item}>
              <Picture
                lazyLoad={isLazyloadImages}
                {...getPictureAssets({
                  bps: theme.bps,
                  imgData: image,
                  ...pictureProps,
                })}
              />
            </TeaserMedia>
            <TeaserHeader
              isGridItem
              {...item}
              typeScale={theme.santaStyle.teaser.headerTypeScaleLabel}
              miscStyles={{ marginTop: [ { until: 's', value: '4rem', }, ], }}
            />
          </Teaser>
        );
      })}
    </ListView>
  );
}
