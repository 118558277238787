// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import Picture from '../../../Image/Picture';
import Image from '../../../Image/Image';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserContent from '../../../TeaserContent/NewTeaserContent';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserReadingTime from '../../../TeaserReadingTime/TeaserReadingTime';
import TeaserReadingArticleCount from '../../../TeaserReadingArticleCount/TeaserReadingArticleCount';
import HtzLink from '../../../HtzLink/HtzLink';
import H from '../../../AutoLevels/H';
import TeaserResponsiveText from '../../../TeaserResponsiveText/TeaserResponsiveText';
import Highlight from '../../../Highlight/Highlight';
import getPictureAssets from '../../../../utils/getPictureAssets';
import getImageAssets from '../../../../utils/getImageAssets';

import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import checkSiteFromConfig from '../../../../utils/checkSiteFromConfig';

const { isHDC, } = checkSiteFromConfig();

type PropsType = {
    item: TeaserDataType,
    biAction: ?() => void,
};

/** ****************************
 *       Muli 1 Teaser
 **************************** */

export function MuliTeaser1({ item, biAction, }: PropsType) {
  const { css, theme, } = useFela();
  const image = item?.mobileImage || item?.image;

  return (
    <Teaser miscStyles={{ position: 'relative', }}>
      <HtzLink
        onClick={biAction}
        href={item.path}
        target={item.linkTarget}
        attrs={{ tabIndex: -1, }}
      >
        <Picture
          {...getPictureAssets({
            bps: theme.bps,
            imgData: image,
            defaultImgOptions: {
              aspect: 'regular',
              sizes: [ { until: 's', size: 'calc(100vw - 24px)', }, ],
              widths: [ 575, ],
            },
            sources: [
              {
                aspect: 'regular',
                from: 'l',
                sizes: [
                  { from: 'xl', size: '624px', },
                  { from: 'l', size: '474px', },
                ],
                widths: [ 624, 474, ],
              },
              {
                aspect: 'belgrade',
                from: 's',
                until: 'l',
                sizes: [
                  { from: 'm', size: '750px', },
                  { from: 's', size: '552px', },
                ],
                widths: [ 750, 552, ],
              },
            ],
          })}
        />
      </HtzLink>
      <div className={css({
        position: 'absolute',
        bottom: 0,
        start: 0,
      })}
      >
        <Highlight
          highlightColor={[ 'ogiList', 'readingtimeHighlight', ]}
          miscStyles={{
            color: theme.color('ogiList', 'readingtimeColor'),
          }}
          isBlock
        >
          {isHDC ? (
            <TeaserReadingArticleCount
              articleCount={item.articleCount}
              miscStyles={{
                padding: '0.5rem',
                type: -2,
              }}
            />
          ) : (
            <TeaserReadingTime
              articleWordCount={item.wordCount}
              iconSize={2}
              miscStyles={{
                type: -2,
                padding: '1rem',
              }}
            />
          )
}
        </Highlight>
        <HtzLink
          href={item.path}
          onClick={biAction}
          target={item.linkTarget}
          className={css({ backgroundColor: theme.color('ogiList', 'headlineBgColor'), })}
        >
          <H className={css({
            color: theme.color('ogiList', 'headlineColor'),
            fontWeight: 400,
            backgroundColor: theme.color('ogiList', 'headlineBgColor'),
            extend: [
              theme.type(4, { untilBp: 's', }),
              theme.type(0, { fromBp: 's', untilBp: 'l', }),
              theme.type(2, { fromBp: 'l', untilBp: 'xl', }),
              theme.type(3, { fromBp: 'xl', }),
            ],
          })}
          >
            <Highlight
              highlightColor={[ 'ogiList', 'headlineBgColor', ]}
              displayBefore={false}
              miscStyles={{
                padding: '1rem',
              }}
            >
              <TeaserResponsiveText text={item.title} mobileText={item.titleMobile} />
            </Highlight>
          </H>
        </HtzLink>
      </div>
    </Teaser>
  );
}

/** ****************************
 *       Muli 2 Teaser
 **************************** */
const muli2Setting = {
  grid: [
    {
      until: 's',
      value: `
        "media media media"
        "  .  content  .  "
        "  .     .     .  "`,
    },
    {
      from: 's',
      value: `".     .        .     ."
              ".   media   content  ."
              ".     .        .     ."`,
    },
  ],
  gap: [
    {
      until: 's',
      value: '1.5rem',
    },
    {
      from: 's',
      value: '2rem',
    },

  ],
  templateColumns: [
    { from: 's', until: 'l', value: '0 3fr 2fr 0', },
    { from: 'l', value: '0 5fr 2fr 0', },
  ],
  templateRows: [
    { from: 's', value: '0 1fr 0', },

  ],
  bgc: [ 'ogiList', 'headlineBgColor', ],
};

export function MuliTeaser2({ item, biAction, }: PropsType) {
  const { theme, } = useFela();
  const image = item?.mobileImage || item?.image;

  return (
    <Teaser
      areasTemplate={muli2Setting.grid}
      gridGap={muli2Setting.gap}
      backgroundColor={muli2Setting.bgc}
      colTemplate={muli2Setting.templateColumns}
      rowTemplate={muli2Setting.templateRows}
    >
      <TeaserMedia data={item}>
        <Image
          image={image}
          imgOptions={getImageAssets({
            bps: theme.bps,
            aspect: 'regular',
            sizes: [
              { from: 'xl', size: '406px', },
              { from: 's', size: '308px', },
              { size: 'calc(100vw - 24px)', },
            ],
            widths: [ 406, 308, ],
          },
          )}
        />
      </TeaserMedia>
      <TeaserContent isCenteredContent={[ { until: 's', value: true, }, ]} miscStyles={{ display: 'flex', flexDirection: 'column', }}>
        <TeaserHeader
          showKicker={false}
          {...item}
          color="white"
          miscStyles={{
            fontWeight: 400,
          }}
          typeScale={[
            { until: 'xl', value: 0, },
            { from: 'xl', value: 1, }, ]}
          onClick={biAction}
        />
        {isHDC ? (
          <TeaserReadingArticleCount
            articleCount={item.articleCount}
            miscStyles={{
              alignSelf: 'center',
              marginTop: 'auto',
              color: theme.color('ogiList', 'readingTimeTextColor'),
              type: -2,
            }}
          />
        ) : (
          <TeaserReadingTime
            articleWordCount={item.wordCount}
            miscStyles={{
              alignSelf: 'center',
              marginTop: 'auto',
              color: theme.color('ogiList', 'headlineColor'),
              type: -2,
            }}
            iconSize={2}
          />
        )}
      </TeaserContent>
    </Teaser>
  );
}

/** ****************************
 *       Muli 3 Teaser
 **************************** */
const muli3Setting = {
  grid: `
    "media media media"
    "  .  content  .  "
    "  .     .     .  "`,
  gap: '1.5rem',
  bgc: [ 'ogiList', 'headlineBgColor', ],
  templateColumns: 'auto 1fr auto',
  templateRows: 'auto 1fr auto',

};

export function MuliTeaser3({ item, biAction, }: PropsType) {
  const { theme, } = useFela();
  const image = item?.mobileImage || item?.image;

  return (
    <Teaser
      areasTemplate={muli3Setting.grid}
      gridGap={muli3Setting.gap}
      backgroundColor={muli3Setting.bgc}
      rowTemplate={muli3Setting.templateRows}
      colTemplate={muli3Setting.templateColumns}
    >
      <TeaserMedia data={item}>
        <Image
          image={image}
          imgOptions={getImageAssets({
            bps: theme.bps,
            aspect: 'regular',
            sizes: [
              { from: 'xl', size: '297px', },
              { from: 'l', size: '225px', },
              { from: 's', size: '264px', },
              { size: 'calc(100vw - 24px)', },
            ],
            widths: [ 297, 225, 264, ],
          }
          )}
        />
      </TeaserMedia>
      <TeaserContent isCenteredContent miscStyles={{ display: 'flex', flexDirection: 'column', }}>
        <TeaserHeader
          showKicker={false}
          {...item}
          miscStyles={{
            fontWeight: 400,
          }}
          color="white"
          typeScale={[
            { until: 'xl', value: 0, },
            { from: 'xl', value: 1, },
          ]}
          onClick={biAction}
        />
        {isHDC ? (
          <TeaserReadingArticleCount
            articleCount={item.articleCount}
            miscStyles={{
              marginTop: 'auto',
              color: theme.color('ogiList', 'readingTimeTextColor'),
              type: -2,
            }}
          />
        ) : (
          <TeaserReadingTime
            articleWordCount={item.wordCount}
            miscStyles={{
              marginTop: 'auto',
              color: theme.color('ogiList', 'headlineColor'),
              type: -2,
            }}
            iconSize={2}
          />
        )}
      </TeaserContent>
    </Teaser>
  );
}

/** ****************************
 *       Muli 4 Teaser
 **************************** */
const muli4Setting = muli3Setting;

export function MuliTeaser4({ item, biAction, }: PropsType) {
  const { theme, } = useFela();
  const image = item?.mobileImage || item?.image;

  return (
    <Teaser
      areasTemplate={muli4Setting.grid}
      gridGap={muli4Setting.gap}
      backgroundColor={muli4Setting.bgc}
      rowTemplate={muli4Setting.templateRows}
      colTemplate={muli4Setting.templateColumns}
    >
      <TeaserMedia data={item}>
        <Picture
          {...getPictureAssets({
            bps: theme.bps,
            imgData: image,
            defaultImgOptions: {
              aspect: 'square',
              sizes: [ { until: 's', size: 'calc(100vw - 24px)', }, ],
              widths: [ 575, ],
            },
            sources: [
              {
                aspect: 'vertical',
                from: 'l',
                sizes: [
                  { from: 'xl', size: '297px', },
                  { from: 'l', size: '225px', },
                ],
                widths: [ 297, 225, ],
              },
              {
                aspect: 'square',
                from: 's',
                until: 'l',
                sizes: [
                  { from: 'm', size: '264px', },
                  { size: '575px', },
                ],
                widths: [ 264, 575, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserContent isCenteredContent miscStyles={{ display: 'flex', flexDirection: 'column', }}>
        <TeaserHeader
          showKicker={false}
          {...item}
          color="white"
          miscStyles={{
            fontWeight: 400,
          }}
          typeScale={[
            { until: 'xl', value: 0, },
            { from: 'xl', value: 1, },
          ]}
          onClick={biAction}
        />
        {isHDC ? (
          <TeaserReadingArticleCount
            articleCount={item.articleCount}
            miscStyles={{
              marginTop: 'auto',
              color: theme.color('ogiList', 'readingTimeTextColor'),
              type: -2,
            }}
          />
        ) : (
          <TeaserReadingTime
            articleWordCount={item.wordCount}
            miscStyles={{
              marginTop: 'auto',
              color: theme.color('ogiList', 'headlineColor'),
              type: -2,
            }}
            iconSize={2}
          />
        )}
      </TeaserContent>
    </Teaser>
  );
}

/** ****************************
 *       Muli 5 Teaser
 **************************** */
const muli5Setting = muli3Setting;

export function MuliTeaser5({ item, biAction, }: PropsType) {
  const { theme, } = useFela();
  const image = item?.mobileImage || item?.image;

  return (
    <Teaser
      areasTemplate={muli5Setting.grid}
      gridGap={muli5Setting.gap}
      backgroundColor={muli5Setting.bgc}
      rowTemplate={muli5Setting.templateRows}
      colTemplate={muli5Setting.templateColumns}
    >
      <TeaserMedia data={item}>
        <Picture
          {...getPictureAssets({
            bps: theme.bps,
            imgData: image,
            defaultImgOptions: {
              aspect: 'regular',
              sizes: [ { until: 's', size: 'calc(100vw - 24px)', }, ],
              widths: [ 575, ],
            },
            sources: [
              {
                aspect: 'vertical',
                from: 'l',
                sizes: [
                  { from: 'xl', size: '297px', },
                  { from: 'l', size: '225px', },
                ],
                widths: [ 297, 225, ],
              },
              {
                aspect: 'square',
                from: 's',
                until: 'l',
                sizes: [
                  { from: 'm', size: '264px', },
                  { size: '575px', },
                ],
                widths: [ 264, 575, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserContent isCenteredContent miscStyles={{ display: 'flex', flexDirection: 'column', }}>
        <TeaserHeader
          showKicker={false}
          {...item}
          miscStyles={{
            fontWeight: 400,
          }}
          color="white"
          typeScale={[
            { until: 'xl', value: 0, },
            { from: 'xl', value: 1, },
          ]}
          onClick={biAction}
        />
        {isHDC ? (
          <TeaserReadingArticleCount
            articleCount={item.articleCount}
            miscStyles={{
              marginTop: 'auto',
              color: theme.color('ogiList', 'readingTimeTextColor'),
              type: -2,
            }}
          />
        )
          : (
            <TeaserReadingTime
              articleWordCount={item.wordCount}
              miscStyles={{
                marginTop: 'auto',
                color: theme.color('ogiList', 'headlineColor'),
                type: -2,
              }}
              iconSize={2}
            />
          )}
      </TeaserContent>
    </Teaser>
  );
}

/** ****************************
 *       Muli 6 Teaser
 **************************** */

export function MuliTeaser6({ item, biAction, }: PropsType) {
  const { css, theme, } = useFela();
  const image = item?.mobileImage || item?.image;

  return (
    <Teaser miscStyles={{
      position: 'relative',
      color: theme.color('ogiList', 'readingtimeColor'),
    }}
    >
      <HtzLink
        onClick={biAction}
        href={item.path}
        target={item.linkTarget}
        attrs={{ tabIndex: -1, }}
      >
        <Image
          image={image}
          imgOptions={getImageAssets({
            bps: theme.bps,
            aspect: 'vertical',
            sizes: [
              { from: 'xl', size: '297px', },
              { from: 'l', size: '225px', },
              { from: 's', size: '264px', },
              { size: 'calc(100vw - 24px)', },
            ],
            widths: [ 297, 225, 264, ],
          }
          )}
        />
      </HtzLink>
      <div className={css({
        position: 'absolute',
        bottom: 0,
        start: 0,
        maxWidth: '75%',
      })}
      >
        <Highlight highlightColor={[ 'ogiList', 'readingtimeHighlight', ]} isBlock>
          {isHDC ? (
            <TeaserReadingArticleCount
              articleCount={item.articleCount}
              miscStyles={{
                type: -2,
                padding: '0.5rem',
              }}
            />
          ) : (
            <TeaserReadingTime
              articleWordCount={item.wordCount}
              iconSize={2}
              miscStyles={{
                type: -2,
              }}
            />
          )}
        </Highlight>
        <HtzLink href={item.path} onClick={biAction} target={item.linkTarget}>
          <H className={css({
            fontWeight: 400,
            extend: [
              theme.type(4, { untilBp: 's', }),
              theme.type(2, { fromBp: 's', untilBp: 'xl', }),
              theme.type(3, { fromBp: 'xl', }),
            ],
          })}
          >
            <div className={css(MuliTeaser5TextStyle)}>
              <TeaserResponsiveText text={item.title} mobileText={item.titleMobile} />
            </div>
          </H>
        </HtzLink>
      </div>
    </Teaser>
  );
}

function MuliTeaser5TextStyle({ theme, }) {
  return {
    display: 'inline',
    boxDecorationBreak: 'clone',
    backgroundColor: theme.color(
      'ogiList',
      'readingtimeHighlight'
    ),
    paddingInlineStart: '1rem',
    paddingInlineEnd: '1rem',
    paddingBlockEnd: '0.3rem',
    paddingBlockStart: '0.3rem',
  };
}
