// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Section from '../../../AutoLevels/Section';
import AdSlot from '../../../AdManager/AdSlot';
import { Comic, Editorial, QuoteTeaser, OpEdTeaser, getItemRenderer, } from '../Spawn/SpawnView';
import AbnerPaginator from './AbnerPaginator';
import { type ContinuableListViewProps, } from '../../../../flowTypes/ListViewProps';

const areasTemplate = [
  {
    until: 's',
    value: `
    "he"
    "comic"
    "ad"
    "editorial"
    "op-ed1"
    "op-ed2"
    "op-ed3"
    "quote"
    "op-ed4"
    "op-ed5"
    `,
  },
  {
    from: 's',
    until: 'l',
    value: `
    "he        he        he"
    "comic     comic     comic"
    "ad        ad        ad"
    "editorial editorial editorial"
    "op-ed1    op-ed2    op-ed3"
    "op-ed4    op-ed4    quote"
    "op-ed5    op-ed5    quote"
    `,
  },
  {
    from: 'l',
    value: `
    "he comic     comic  ad     ad"
    "he editorial op-ed1 op-ed2 op-ed3"
    "he op-ed4    op-ed5 op-ed5 quote"
    `,
  },
];

const teasers = [
  AbnerComic,
  Editorial,
  OpEdTeaser,
  OpEdTeaser,
  OpEdTeaser,
  OpEdTeaser,
  OpEdTeaser,
  QuoteTeaser,
];

export const teaserGridAreaNames = [
  'comic',
  'editorial',
  'op-ed1',
  'op-ed2',
  'op-ed3',
  'op-ed4',
  'op-ed5',
  'quote',
];

function AbnerComic(props) {
  return <Comic {...props} miscStyles={{}} />;
}

Abner.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
};

// This list view is based on Spawn view.
// Diffs are: Always displays the caricature as first element.
// Has a banner as scound element.
// Has pagination.
export default function Abner({
  list,
  listId,
  history,
  isLazyloadImages,
  biAction,
  gaAction,
}: ContinuableListViewProps): React.Node {
  const { theme, } = useFela();
  const { items, banners, extraLinks, hasPagination, itemsCount, ...restOfList } = list || {};
  const adSlotsData = banners?.adSlots?.length > 0 && banners.adSlots[0];
  if (!(items && items.length)) return null;

  const gridAreaToHideOnMobile = [];

  const itemRenderer = getItemRenderer(
    biAction,
    isLazyloadImages,
    teasers,
    teaserGridAreaNames,
    theme,
    gridAreaToHideOnMobile,
  );

  return (
    <ListView
      areasTemplate={areasTemplate}
      colTemplate={[
        { from: 's', until: 'l', value: '1fr 1fr 1fr', },
        { from: 'l', value: '1fr 2fr 1fr 1fr 1fr', },
      ]}
      Paginator={hasPagination && items?.length >= itemsCount ? ({ namedBgc, gridGap, colGap, rowGap, padding, }) => (
        <AbnerPaginator
          listId={listId}
          history={history}
          biAction={biAction}
          isLazyloadImages={isLazyloadImages}
          gridGap={gridGap}
          colGap={colGap}
          rowGap={rowGap}
          padding={padding}
          namedBgc={namedBgc}
        />
      ) : null}
      attrs={{
        'data-test': 'abner',
      }}
    >
      {restOfList.title || restOfList.commercialLinks?.length || extraLinks?.length ? (
        <ListViewHeader
          {...restOfList}
          extraLinks={extraLinks?.length ? extraLinks.slice(0, 5) : null}
          biAction={biAction}
          isSticky
        />
      ) : null}
      <Section isFragment>
        {items.map(itemRenderer)}
        {adSlotsData ? (
          <AdSlot
            {...adSlotsData}
            loadPriority={list.loadPriority}
            wrapperMiscStyles={{
              backgroundColor: '#f6f6f6',
              display: [ { until: 's', value: 'none', }, { from: 's', value: 'flex', }, ],
              gridArea: 'ad',
              justifyContent: 'center',
              justifyItems: 'center',
              margin: 'auto',
              minHeight: '250px',
              minWidth: '300px',
              overflow: 'hidden',
              width: [ { from: 'l', until: 'xl', value: '120px', }, { from: 'xl', value: '300px', }, ],
            }}
          />
        ) : null}
      </Section>
    </ListView>
  );
}
