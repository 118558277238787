// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';

import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import type { ClickTrackerBannerType, } from '../../../../flowTypes/ClickTrackerBannerType';

import getPictureAssets from '../../../../utils/getPictureAssets';

import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Picture from '../../../Image/Picture';
import Section from '../../../AutoLevels/Section';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import ClickTracker from '../../../ClickTracker/ClickTrackerWrapper';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';
import useOneTime from '../../../../hooks/useOneTime';

SantaView.defaultProps = {
  biAction: null,
  biImpression: null,
  gaAction: null,
  isLazyloadImages: true,
  inView: false,
};

const getAreasTemplate = listLength => {
  let untilLDynamicTemplate = '"t1 t2"';
  let fromLDynamicTemplate = '" he t1 t2"';
  for (let index = 2; index < listLength; index += 2) {
    untilLDynamicTemplate += `"t${index + 1} t${index + 2}"`;
    fromLDynamicTemplate += `"he t${index + 1} t${index + 2}"`;
  }

  return [
    {
      until: 'l',
      value: `
  "he he"
  "t1 t2"
  ${untilLDynamicTemplate}
  `,
    },
    {
      from: 'l',
      value: `
    "he t1 t2"
    ${fromLDynamicTemplate}
    `,
    },
  ];
};
const teaserProps = {
  areasTemplate: [
    {
      from: 'l',
      value: `
    "media . . ."
    "media . . ."
    "media . content ."
    "media . footer ."
    "media . . ."
    `,
    },
    {
      until: 'l',
      value: `
    "media media media"
    ". content ."
    ". footer ."
    ". . ."
    `,
    },
  ],
  colTemplate: [
    { from: 'xl', value: '3fr 0 2fr 0', },
    { from: 'l', until: 'xl', value: '2fr 0 3fr 0', },
    { until: 'l', value: ' 0 1fr 0', },
  ],
  rowTemplate: [ { from: 'l', value: '0 0 1fr auto 0', }, { until: 'l', value: 'auto 1fr auto 0', }, ],
  colGap: '1rem',
  rowGap: '1rem',
};

const pictureProps = {
  defaultImgOptions: {
    sizes: [
      { from: 'xl', size: '300px', },
      { from: 'l', size: '145px', },
      { from: 'm', size: '350px', },
      { from: 's', size: '260px', },
      { size: '285px', },
    ],
    aspect: 'regular',
    widths: [ 145, 300, 400, 600, 800, ],
  },
  sources: [
    {
      aspect: 'regular',
      until: 's',
      sizes: '285px',
      widths: [ 285, 600, 800, ],
    },
    {
      aspect: 'headline',
      from: 's',
      until: 'm',
      sizes: '260px',
      widths: [ 260, 350, 520, 700, ],
    },
    {
      aspect: 'headline',
      from: 'm',
      until: 'l',
      sizes: '350px',
      widths: [ 350, 450, 700, ],
    },
    {
      aspect: 'square',
      from: 'l',
      until: 'xl',
      sizes: '145px',
      widths: [ 145, 300, 450, ],
    },
    {
      aspect: 'regular',
      from: 'xl',
      sizes: '275px',
      widths: [ 275, 550, 1024, ],
    },
  ],
};

export default function SantaView({
  list,
  isLazyloadImages,
  biAction: rawBiAction,
  biImpression,
  gaAction,
  inView,
}: ListViewProps): React.Node {
  const { dfp, items, clickTrackers, extraLinks, ...restOfList } = list || {};
  const { theme, css, } = useFela();

  const clickTracker = clickTrackers ? clickTrackers[0] : null;
  const { teaser, } = theme.santaStyle;

  useOneTime(items && !!biImpression && typeof biImpression === 'function' && !!inView, () => items.map((item, i) => {
    i < 10 && biImpression && typeof setBiImpression === 'function' && setBiImpression(i, item, biImpression)();
    return null;
  }));

  return (
    <ListView
      areasTemplate={getAreasTemplate([ ...items, ...(dfp || []), ...(clickTrackers || []), ].length)}
      colTemplate={[ { until: 'l', value: '1fr 1fr', }, { from: 'l', value: '2fr 5fr 5fr', }, ]}
      rowGap={[ { until: 's', value: '2rem', }, ]}
      attrs={{
        'data-test': 'santa',
      }}
    >
      <ListViewHeader
        {...restOfList}
        extraLinks={extraLinks?.length ? extraLinks.slice(0, 5) : null}
        biAction={rawBiAction}
        isSticky
      />
      {items.map((item, idx) => {
        const image = item?.mobileImage || item?.image;
        const biAction = setBiAction(idx, item, rawBiAction);
        return (
          <Section isFragment>
            <Teaser
              gridArea={`t${idx + 1}`}
              {...teaserProps}
              key={item.contentId}
            >
              <TeaserMedia
                data={item}
                onClick={biAction}
              >
                <Picture
                  lazyLoad={isLazyloadImages}
                  {...getPictureAssets({
                    bps: theme.bps,
                    imgData: image,
                    ...pictureProps,
                  })}
                />
              </TeaserMedia>
              <TeaserHeader
                isGridItem
                {...item}
                typeScale={teaser.headerTypeScale}
                miscStyles={{ marginTop: [ { until: 's', value: '4rem', }, ], }}
                onClick={biAction}
              />
              <TeaserFooter
                noCommentsOnMobile
                showAuthor
                showTime={teaser.footer.showTime}
                showCommentsCount={teaser.footer.showCommentsCount}
                data={item}
                typeScale={[
                  { until: 's', value: -3, },
                  { from: 's', until: 'xl', value: -2, },
                  { from: 'xl', value: -3, },
                ]}
                miscStyles={teaser.footer.miscStyles}
              />
            </Teaser>
          </Section>
        );
      })}
      {clickTracker && (
        <ClickTracker
          {...clickTracker}
          render={(banner: ClickTrackerBannerType) => {
            const { text, link, clicktrackerimage, } = banner;
            return (
              <Teaser
                {...teaserProps}
                miscStyles={{
                  fontFamily: theme.fontStacks.commercial,
                  //  display: [ { until: 'l', value: 'none', }, ],
                }}
              >
                <TeaserMedia data={banner} isClickTracker>
                  <Picture
                    lazyLoad={isLazyloadImages}
                    {...getPictureAssets({
                      bps: theme.bps,
                      imgData: clicktrackerimage,
                      ...pictureProps,
                    })}
                  />
                </TeaserMedia>
                <TeaserHeader
                  isGridItem
                  {...{
                    title: text || '',
                    path: link,
                  }}
                  typeScale={[
                    { until: 's', value: -1, },
                    { from: 's', until: 'l', value: 2, },
                    { from: 'xl', value: 0, },
                  ]}
                  miscStyles={{ marginTop: [ { until: 's', value: '4rem', }, ], }}
                />
                <div
                  className={css({
                    gridArea: 'footer',
                    textAlign: 'end',
                    color: theme.color('commercial'),
                    extend: [ theme.type(-2), ],
                  })}
                >
                  {theme.clickTrackerI18n.promotedContentLabel}
                </div>
              </Teaser>
            );
          }}
        />
      )}
    </ListView>
  );
}
