/* eslint-disable react/no-unused-prop-types */
// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { type StyleProps, parseStyleProps, } from '@haaretz/htz-css-tools';

type PropsType = {
    min: number,
    max: number,
    value: number,
    isShowLabels?: boolean,
    miscStyles: ?StyleProps,
    progressBarMiscStyles: ?StyleProps,
    progressBarValueMiscStyles: ?StyleProps,
    labelsMiscStyles: ?StyleProps,
    minLabelMiscStyles: ?StyleProps,
    maxLabelMiscStyles: ?StyleProps,
    valueLabelMiscStyles: ?StyleProps,
};

ProgressBar.defaultProps = {
  min: 0,
  max: 100,
  value: 0,
  isShowLabels: false,
  miscStyles: null,
  progressBarMiscStyles: null,
  progressBarValueMiscStyles: null,
  labelsMiscStyles: null,
  minLabelMiscStyles: null,
  maxLabelMiscStyles: null,
  valueLabelMiscStyles: null,
};

const transitionDuration = '.8s';

function progressBarElementStyle({ theme, miscStyles, isShowLabels, }) {
  return {
    display: 'grid',
    gridTemplateAreas: isShowLabels
      ? `
        "min-label      bar       max-label"
        "   .       value-label      .     "`
      : '"bar"',
    gridTemplateColumns: isShowLabels ? 'auto 1fr auto' : '1fr',
    extend: [
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type)
        : []),
    ],
  };
}

function progressBarStyle({ theme, progressBarMiscStyles, }) {
  return {
    gridArea: 'bar',
    display: 'flex',
    backgroundColor: theme.color('neutral', '-5'),
    extend: [
      ...(progressBarMiscStyles ? parseStyleProps(progressBarMiscStyles, theme.mq, theme.type)
        : []),
    ],
  };
}

function progressBarValueStyle({ theme, progressBarValueMiscStyles, valueInPercents, }) {
  return {
    backgroundColor: theme.color('quaternary'),
    width: `${valueInPercents}%`,
    borderInlineEnd: `2px solid ${theme.color('black')}`,
    transition: `width ${transitionDuration} ease-in`,
    extend: [
      ...(progressBarValueMiscStyles ? parseStyleProps(progressBarValueMiscStyles, theme.mq, theme.type)
        : []),
    ],
  };
}

function minLabelStyle({ theme, labelsMiscStyles, minLabelMiscStyles, }) {
  return {
    gridArea: 'min-label',
    paddingInlineEnd: '1rem',
    extend: [
      ...(labelsMiscStyles ? parseStyleProps(labelsMiscStyles, theme.mq, theme.type)
        : []),
      ...(minLabelMiscStyles ? parseStyleProps(minLabelMiscStyles, theme.mq, theme.type)
        : []),
    ],
  };
}

function maxLabelStyle({ theme, labelsMiscStyles, maxLabelMiscStyles, }) {
  return {
    gridArea: 'max-label',
    paddingInlineStart: '1rem',
    extend: [
      ...(labelsMiscStyles ? parseStyleProps(labelsMiscStyles, theme.mq, theme.type)
        : []),
      ...(maxLabelMiscStyles ? parseStyleProps(maxLabelMiscStyles, theme.mq, theme.type)
        : []),
    ],
  };
}

function valueLabelStyle({ theme, labelsMiscStyles, valueLabelMiscStyles, valueInPercents, }) {
  return {
    gridArea: 'value-label',
    position: 'relative',
    start: `${valueInPercents}%`,
    transition: `all ${transitionDuration} ease-in`,
    fontSize: '0.75em',
    extend: [
      ...(labelsMiscStyles ? parseStyleProps(labelsMiscStyles, theme.mq, theme.type)
        : []),
      ...(valueLabelMiscStyles ? parseStyleProps(valueLabelMiscStyles, theme.mq, theme.type)
        : []),
    ],
  };
}

export default function ProgressBar(props: PropsType) {
  const { min, max, value, isShowLabels, } = props;
  const valueInPercents = 100 * ((value - min) / (max - min));
  const { css, } = useFela({ ...props, valueInPercents, });
  return (
    <div className={css(progressBarElementStyle)} role="progressbar" aria-valuenow={value} aria-valuemin={min} aria-valuemax={max}>
      <div className={css(progressBarStyle)}>
        <div className={css(progressBarValueStyle)} />
      </div>
      {isShowLabels ? (
        <>
          <span className={css(minLabelStyle)}>{min}</span>
          <span className={css(maxLabelStyle)}>{max}</span>
          <span className={css(valueLabelStyle)}>{value}</span>
        </>
      )
        : null}

    </div>
  );
}
