// @flow

import * as React from 'react';
import ListView from '../../../ListView/NewListView';
import NilsTeaser from '../Nils/NilsTeaser';
import setBiAction from '../../../../utils/setBiAction';
import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';

const gridTemplateColumns = [
  { until: 's', value: '1fr', },
  { from: 's', until: 'l', value: '1fr 1fr', },
  { from: 'l', value: '1fr 1fr 1fr', },
];

export default function ShushuView({
  list,
  isLazyloadImages,
  gaAction,
  biAction,
}: ListViewProps) {
  return (
    <ListView
      colTemplate={gridTemplateColumns}
      padding={[ { until: 's', value: '0 2rem', }, { from: 's', until: 'xl', value: '0 4rem', }, { from: 'xl', value: '0', }, ]}
      attrs={{
        'data-test': 'shushu',
      }}
    >
      {list.items.map(item => (
        <NilsTeaser
          item={item}
          biAction={setBiAction(0, item, biAction)}
        />
      ))}
    </ListView>
  );
}
