// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';

import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';

import Image from '../../../Image/Image';
import ListView from '../../../ListView/NewListView';
import Picture from '../../../Image/Picture';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import getImageAssets from '../../../../utils/getImageAssets';
import getPictureAssets from '../../../../utils/getPictureAssets';
import setBiAction from '../../../../utils/setBiAction';
import Section from '../../../AutoLevels/Section';

type Props = {
  isLazyloadImages: boolean,
  isWideMain: boolean,
  list: ListDataType,
  biAction: ?ListBiActionType,
};

PazuzuLabelsView.defaultProps = {
  isWideMain: false,
  isLazyloadImages: false,
};

export default function PazuzuLabelsView({
  isWideMain,
  list,
  isLazyloadImages,
  biAction,
}: Props): React.Node {
  const { theme, } = useFela();
  const { items, } = list || {};

  if (!items || !items.length) return null;
  return (
    <ListView
      disableWrapper
      keepHeadingLevel
      colGap={[ { until: 's', value: '2', }, { from: 's', value: '4', }, ]}
      rowGap={null}
      gridGap={null}
      colTemplate="1fr 1fr"
      outerBackgroundColor={[ { from: 's', value: 'white', }, ]}
      miscStyles={isWideMain ? null : { gridColumnEnd: [ { from: 'xl', value: 'span 2', }, ], }}
      padding={[ { until: 's', value: '0 2rem', }, { from: 's', value: '0', }, ]}
      attrs={{
        'data-test': 'pazuzuLabels',
      }}
    >
      {items.slice(0, 2).map((item, i) => (
        <PazuzuTeaser
          key={item.contentId}
          isLazyloadImages={isLazyloadImages}
          isStackedOnXl={isWideMain}
          item={item}
          biAction={setBiAction(i, item, biAction)}
          theme={theme}
          isCommercial
        />
      ))}
    </ListView>
  );
}

type PazuzuTeaserPropTypes = {
  item: TeaserDataType,
  isStackedOnXl: boolean,
  isLazyloadImages: boolean,
  theme: Object,
  biAction: ?() => void,
};

const areasTeaser = `
"media ..."
"media  content"
"media  ..."
`;

const areasStackedTeaser = `
"media media   media"
"...   content   ..."
"...   ...       ..."
`;

PazuzuTeaser.defaultProps = { isLazyloadImages: false, };
function PazuzuTeaser({
  item,
  isStackedOnXl,
  isLazyloadImages,
  biAction,
  theme,
}: PazuzuTeaserPropTypes): React.Node {
  const image = item?.mobileImage || item?.image;

  return (
    <Section style={{
      border: `1px solid ${theme.color('neutral', '-5')}`,
      backgroundColor: 'white',
      fontFamily: theme.fontStacks.commercial,
    }}
    >
      <Teaser
        areasTemplate={[
          { until: 'm', value: areasStackedTeaser, },
          { from: 'm', value: areasTeaser, },
        ]}
        colTemplate={[
          { until: 'm', value: '1rem 1fr 2rem', },
          { from: 'm', value: '40rem 1fr', },
        ]}
        rowTemplate={[
          { until: 'm', value: 'auto 1fr 3rem', },
          { from: 'm', until: 'xl', value: '0 auto auto', },
          { from: 'xl', value: '1rem auto auto', },
        ]}
        rowGap="1rem"
        colGap={[
          { until: 'm', value: '1rem', },
          { from: 'm', value: '2rem', },
        ]}
        gridGap={null}
        onClick={biAction}
      >
        <TeaserMedia data={item} onClick={biAction}>
          {isStackedOnXl ? (
            <Picture
              lazyLoad={isLazyloadImages}
              {...getPictureAssets({
                bps: theme.bps,
                imgData: image,
                defaultImgOptions: {
                  sizes: [
                    { from: 'l', size: '314px', },
                    { from: 'm', size: '348px', },
                    { from: 's', size: '264px', },
                    { size: 'calc(50vw - 36px)', },
                  ],
                  aspect: 'headline',
                  widths: [ 170, 265, 314, 350, 600, ],
                },
                sources: [
                  {
                    aspect: 'landscape',
                    from: 'xl',
                    sizes: [ { size: '389px', }, ],
                    widths: [ 389, ],
                  },
                ],
              })}
            />
          ) : (
            <Image
              lazyLoad={isLazyloadImages}
              image={image}
              imgOptions={getImageAssets({
                bps: theme.bps,
                aspect: 'headline',
                sizes: [
                  { from: 'xl', size: '336px', },
                  { from: 'l', size: '314px', },
                  { from: 'm', size: '348px', },
                  { from: 's', size: '264px', },
                  { size: 'calc(50vw - 36px)', },
                ],
                widths: [ 170, 200, 240, 265, 280, 315, 350, 600, ],
              })}
            />
          )}
        </TeaserMedia>
        <TeaserHeader
          isGridItem
          {...item}
          typeScale={theme.pazuzuStyle.teaser.headerTypeScaleLabel}
          onClick={biAction}
        />
      </Teaser>
    </Section>
  );
}
