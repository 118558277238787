// @flow
/* global window */
import React from 'react';
import { SANTA_LABELS_LIST_QUERY, } from '@haaretz/graphql';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

import SantaLabelsView from './SantaLabelsView.js';

type SantaLabelsWrapperProps = {
  updateListDuplication: Function,
  variables: {
    history: ?(string[]),
    getHistory: ?(() => string[]),
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
}

export default function SantaLabelsWrapper(props: SantaLabelsWrapperProps) {
  return (
    <ListWrapper {...props} query={SANTA_LABELS_LIST_QUERY} view="SantaLabels">
      {dataProps => <SantaLabelsView {...dataProps} />}
    </ListWrapper>
  );
}
