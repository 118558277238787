import React from 'react';
import { useFela, } from 'react-fela';
import Picture from './Picture';
import Image from './Image';
import LayoutContainer from '../PageLayout/LayoutContainer';
import getImageAssets from '../../utils/getImageAssets';
import HtzLink from '../HtzLink/HtzLink';
import EventTracker from '../../utils/EventTracker';

export default function WidePageImage({ imageUrlLinkGroup, imageUrlLinkGroupTarget, ...props }) {
  const { theme, } = useFela();
  const sourceOptionsData = {
    bps: theme.bps,
    sizes: [
      { from: 'xl', size: '1300px', },
      { from: 'l', size: '1080px', },
      { from: 'm', size: '800px', },
      { from: 's', size: '600px', },
      { size: '100vw', },
    ],
    aspect: 'full',
    widths: [ 320, 350, 600, 800, 1080, 1300, 2400, ],
  };
  // const { imageUrlLinkGroup, imageUrlLinkGroupTarget, } = props;
  const Wrapper = imageUrlLinkGroup ? HtzLink : React.Fragment;
  return (
    <LayoutContainer
      miscStyles={{
        paddingBottom: [ { from: 's', value: '2rem', }, { from: 's', value: '4rem', }, ],
        paddingTop: [ { from: 's', value: '2rem', }, { from: 's', value: '4rem', }, ],
      }}
    >
      <EventTracker>
        {({ biAction, }) => (
          <Wrapper
            {...(imageUrlLinkGroup
              ? {
                href: imageUrlLinkGroup,
                target: imageUrlLinkGroupTarget || '_blank',
                onClick: () => biAction({
                  actionCode: 3,
                  feature: 'banner',
                  featureType: 'Marketing',
                  campaignName: 'kinneret_2019',
                  campaignDetails: 'לרכישה',
                }),
              }
              : {})}
          >
            {/* eslint-disable-next-line react/prop-types */}
            {props.imgArray && props.imgArray.length > 1 ? (
              <Picture
                data={props}
                defaultImg={{
                  positionInImgArray: 1,
                  sourceOptions: getImageAssets(sourceOptionsData),
                }}
                sources={[
                  {
                    from: 's',
                    positionInImgArray: 0,
                    sourceOptions: getImageAssets(sourceOptionsData),
                  },
                ]}
              />
            ) : (
              <Image image={props} imgOptions={getImageAssets(sourceOptionsData)} />
            )}
          </Wrapper>
        )}
      </EventTracker>
    </LayoutContainer>
  );
}
