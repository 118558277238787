// @flow
/* global window */
import React from 'react';
import { TATE_LABELS_LIST_QUERY, } from '@haaretz/graphql';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

import TateLabelsView from './TateLabelsView.js';

type TateLabelsWrapperProps = {
  updateListDuplication: Function,
  variables: {
    history: ?(string[]),
    getHistory: ?(() => string[]),
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
}

export default function TateLabelsWrapper(props: TateLabelsWrapperProps) {
  return (
    <ListWrapper {...props} query={TATE_LABELS_LIST_QUERY} view="TateLabels">
      {dataProps => <TateLabelsView {...dataProps} />}
    </ListWrapper>
  );
}
