// @flow
/* global window */
import React from 'react';
import { ZAPP_LABELS_LIST_QUERY, } from '@haaretz/graphql';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

import ZappLabelsView from './ZappLabelsView.js';

type ZappLabelsWrapperProps = {
  updateListDuplication: Function,
  variables: {
    history: ?(string[]),
    getHistory: ?(() => string[]),
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
}

export default function ZappLabelsWrapper(props: ZappLabelsWrapperProps) {
  return (
    <ListWrapper {...props} query={ZAPP_LABELS_LIST_QUERY} view="ZappLabels">
      {dataProps => <ZappLabelsView {...dataProps} />}
    </ListWrapper>
  );
}
