// @flow
import * as React from 'react';
import { ABNER_LIST_QUERY, } from '@haaretz/graphql';
import AbnerView from './AbnerView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type AbnerWrapperProps = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
};


export default function AbnerWrapper(props: AbnerWrapperProps) {
  return (
    <ListWrapper {...props} query={ABNER_LIST_QUERY} view="Abner">
      {dataProps => <AbnerView {...dataProps} />}
    </ListWrapper>
  );
}
