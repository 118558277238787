// @flow
import * as React from 'react';
import { OGI_LIST_QUERY, } from '@haaretz/graphql';
import OgiView from './OgiView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type OgiWrapperProps = {
  updateListDuplication: Function,
  listData: ListDataType,
  isPersonal: boolean,
}

export default function OgiWrapper(props: OgiWrapperProps) {
  return (
    <ListWrapper {...props} query={OGI_LIST_QUERY} view="Ogi">
      {dataProps => <OgiView {...dataProps} />}
    </ListWrapper>
  );
}
