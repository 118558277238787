// @flow
import * as React from 'react';
import { CLAMPS_LIST_QUERY, } from '@haaretz/graphql';
import ClampsView from './ClampsView.js';
import ListWrapper from '../../ListWrapper';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';

type ClampsWrapperProps = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
}
export default function ClampsWrapper(props: ClampsWrapperProps): React.Node {
  return (
    <ListWrapper {...props} query={CLAMPS_LIST_QUERY} view="Clamps">
      {dataProps => <ClampsView {...dataProps} />}
    </ListWrapper>
  );
}
