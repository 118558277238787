// @flow

import * as React from 'react';
import ListView from '../../../ListView/NewListView';
// import OgiTeaser from './OgiTeaser';
import AkaTeaser from '../Aka/AkaTeaser';

import setBiAction from '../../../../utils/setBiAction';
import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';

export default function OgiView({
  list,
  isLazyloadImages,
  gaAction,
  biAction,
}: ListViewProps) {
  const item = list.items[0];
  return (
    <ListView
      miscStyles={{ backgroundColor: [ { until: 's', value: list.imageBg, }, ], }}
      padding={[ { until: 's', value: '0', }, { from: 's', until: 'xl', value: '0 4rem', }, { from: 'xl', value: '0', }, ]}
      attrs={{
        'data-test': 'ogi',
      }}
    >
      <AkaTeaser
        item={item}
        biAction={setBiAction(0, item, biAction)}
      />
    </ListView>
  );
}
