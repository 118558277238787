// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { type StyleProps, parseStyleProps, } from '@haaretz/htz-css-tools';

const transitionDuration = '0.4s';
const progressBarContainerWidthRem = 56;

type PropsType = {
  totalArticles: number,
  articlesRead: number,
  miscStyles: ?StyleProps,
  progressBarMiscStyles: ?StyleProps,
  progressBarValueMiscStyles: ?StyleProps,
};

ProgressBar.defaultProps = {
  totalArticles: 0,
  articlesRead: 0,
  miscStyles: null,
  progressBarMiscStyles: null,
  progressBarValueMiscStyles: null,
};


function progressBarContainerStyle({ theme, miscStyles, }) {
  return {
    width: `${progressBarContainerWidthRem}rem`,
    padding: '1rem',
    textAlign: 'center',
    borderRadius: '1.5rem',
    border: '1px solid #2d2d2d',
    extend: [
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type)
        : []),
    ],
  };
}
function progressBarStyle({ theme, progressBarMiscStyles, }) {
  return {
    gridArea: 'bar',
    display: 'flex',
    alignItems: 'center',
    extend: [
      ...(progressBarMiscStyles ? parseStyleProps(progressBarMiscStyles, theme.mq, theme.type)
        : []),
    ],
  };
}
function progressBarSegmentStyle({ theme, isFilled, progressBarValueMiscStyles, totalArticles, }) {
  if (totalArticles === 0) return null;
  let margin;
  let borderRadius;
  if (totalArticles < 10) {
    margin = '0 1rem';
    borderRadius = '1.5rem';
  }
  else if (totalArticles >= 10 && totalArticles < 20) {
    margin = '0 0.36rem';
    borderRadius = '2rem';
  }
  else {
    margin = '0 0.14rem';
    borderRadius = '3rem';
  }
  const segmentWidth = progressBarContainerWidthRem / totalArticles;

  return {
    display: 'flex',
    width: `${segmentWidth}rem`, // Dynamic width based on the number of articles and margins
    height: '1rem',
    margin, // Dynamic margin based on the number of articles
    alignItems: 'center',
    justifyContent: 'center',
    transition: `width ${transitionDuration} ease-in`,
    borderRadius,
    border: '1px solid #2d2d2d',
    backgroundColor: isFilled ? '#2d2d2d' : 'transparent',
    extend: [
      ...(progressBarValueMiscStyles ? parseStyleProps(progressBarValueMiscStyles, theme.mq, theme.type) : []),
    ],
  };
}

export default function ProgressBar(props: PropsType) {
  const { totalArticles, articlesRead, } = props;
  const { css, } = useFela({ ...props, totalArticles, articlesRead, });
  return (
    <div className={css(progressBarContainerStyle)} role="progressbar" aria-valuenow={articlesRead} aria-valuemin={articlesRead} aria-valuemax={totalArticles}>
      <div className={css(progressBarStyle)}>
        {Array.from({ length: totalArticles, }, (_, index) => (
          <div key={`${index}-progressBarSegment`} className={css(progressBarSegmentStyle({ isFilled: index < articlesRead, totalArticles, }))} />
        ))}
      </div>
    </div>
  );
}
