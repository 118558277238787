// @flow
import { image, embed, imageGallery, } from '@haaretz/app-utils';
import * as React from 'react';
import gql from 'graphql-tag';

import { WONG_LIST_QUERY, } from '@haaretz/graphql';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';
import WongView from './WongView.js';

export const WongQuery = gql`
  query WongQuery($input: ListInput!) {
    list(input: $input) {
      dedupeReadingHistory
      isLazyloadImages
      preventRender {
        from
        until
      }
      items {
        ... on Teaser {
          assetId
          inputTemplate
          commentsCounts
          contentId
          hash
          representedContent
          representedContentType
          title
          titleMobile
          subtitle
          subtitleMobile
          exclusive
          exclusiveMobile
          path
          publishDate
          lastUpdate
          liveUpdates
          openInNewWindow
          relatedArticles {
            title
            path
            contentId
          }
          authors {
            contentName
          }
          media {
            ... on Image {
              ...Image
            }
            ... on Embed {
              ...Embed
            }
            ... on ImageGallery {
              ...ImageGallery
            }
          }
        }
      }
    }
  }
  ${embed}
  ${image}
  ${imageGallery}
`;

type WongWrapperProps = {
  updateListDuplication: Function,
  listData: ListDataType,
}

export default function WongWrapper(props: WongWrapperProps): React.Node {
  return (
    <ListWrapper {...props} query={WONG_LIST_QUERY} view="Wong">
      {dataProps => <WongView {...dataProps} />}
    </ListWrapper>
  );
}
