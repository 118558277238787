// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import type { Node, } from 'react';

import H from '../../../AutoLevels/H';
import TeaserResponsiveText from '../../../TeaserResponsiveText/TeaserResponsiveText';
import Highlight from '../../../Highlight/Highlight';
import { BlockLinkInner, } from '../../../BlockLink/BlockLink';
import AboveBlockLink from '../../../BlockLink/AboveBlockLink';
import HtzLink from '../../../HtzLink/HtzLink';
import getTeaserHref from '../../../../utils/getTeaserHref';

import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

type Props = {
  item: TeaserDataType,
  children: Node,
  offset?: number,
  onClick?: ?Function,
  gridArea?: ?string,
};

BoxyInverseLabelsItem.defaultProps = {
  offset: 1,
  onClick: null,
  gridArea: null,
};

export default function BoxyInverseLabelsItem({
  item,
  children,
  offset,
  onClick,
  gridArea,
}: Props): Node {
  const { css, theme, } = useFela();
  const href = getTeaserHref(item);
  const fontWeight = '200';
  return (
    <div
      className={css({
        overflow: 'hidden',
        position: 'relative',
        gridArea: gridArea || null,
        fontFamily: theme.fontStacks.commercial,
        marginTop: '5rem',
      })}
    >
      {children}
      <AboveBlockLink>
        {({ className, }) => (
          <div className={css(textWrapperStyle)}>
            {(item.exclusive || item.exclusiveMobile) && (
              <Highlight
                isBlock
                highlightColor={[ 'boxy', 'innerTextBgLabel', ]}
                miscStyles={{
                  type: -1,
                  fontWeight,
                  color: [ 'boxy', 'innerTextLabel', ],
                }}
              >
                <TeaserResponsiveText
                  text={item.exclusive}
                  mobileText={item.exclusiveMobile}
                />
              </Highlight>
            )}
            {item.title && (
              // We use an offset here, because the title should be the same level
              // as a header inside a section, no the same as a section's title
              <H className={css(headlineStyle)} offset={offset}>
                <HtzLink href={href} className={className} onClick={onClick}>
                  <Highlight
                    highlightColor={[ 'boxy', 'innerTextBgLabel', ]}
                    miscStyles={{
                      color: theme.color('boxy', 'innerTextLabel'),
                      fontWeight,
                      opacity: '0.92',
                      letterSpacing: '1px',
                    }}
                  >
                    <TeaserResponsiveText
                      text={item.title}
                      mobileText={item.titleMobile}
                    />
                  </Highlight>
                </HtzLink>
              </H>
            )}
          </div>
        )}
      </AboveBlockLink>
      <BlockLinkInner href={href} onClick={onClick} />
    </div>
  );
}

// /////////////////////////////////////////////////////////////////////
//                               STYLE                                //
// /////////////////////////////////////////////////////////////////////

function textWrapperStyle() {
  return {
    position: 'absolute',
    start: 0,
    bottom: 0,
    maxWidth: '90%',
  };
}

function headlineStyle({ theme, }) {
  return {
    extend: theme.boxyStyle.boxyLabelItem({ theme, }).themeType,
  };
}
