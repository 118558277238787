// @flow
import * as React from 'react';
import { MULI_LIST_QUERY, } from '@haaretz/graphql';
import MuliView from './MuliView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type MuliWrapperProps = {
  updateListDuplication: Function,
  listData: ListDataType,
  isPersonal: boolean,
}

export default function MuliWrapper(props: MuliWrapperProps) {
  return (
    <ListWrapper {...props} query={MULI_LIST_QUERY} view="Muli">
      {dataProps => <MuliView {...dataProps} />}
    </ListWrapper>
  );
}
