// @flow
import * as React from 'react';
import { BOXY_INVERSE_LABELS_LIST_QUERY, } from '@haaretz/graphql';
import BoxyInverseLabelsView from './BoxyInverseLabelsView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type BoxyInverseLabelsProps = {
  updateListDuplication: Function,
  variables: {
    history: ?(string[]),
    getHistory: ?(() => string[]),
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
}

export default function BoxyInverseLabelsWrapper(props: BoxyInverseLabelsProps) {
  return (
    <ListWrapper {...props} query={BOXY_INVERSE_LABELS_LIST_QUERY} view="BoxyInverseLabels">
      {dataProps => (
        <BoxyInverseLabelsView {...dataProps} />
      )}
    </ListWrapper>
  );
}
