// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { type ComponentPropResponsiveObject, } from '@haaretz/htz-css-tools';
import { TATE_LIST_QUERY, } from '@haaretz/graphql';
import Paginator from '../../Paginator';
import TateLabelsTeaser from './TateLabelsTeaser';
import type { ColorPropType, } from '../../../../flowTypes/ColorPropType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import setBiAction from '../../../../utils/setBiAction';

type PropsType = {
  listId: string,
  sectionId?: ?string,
  history: ?Array<string>,
  biAction: ?ListBiActionType,
  isLazyloadImages: boolean,
  namedBgc: ?ColorPropType,
  gridGap: ?string | Array<ComponentPropResponsiveObject<string>>,
  colGap: ?string | Array<ComponentPropResponsiveObject<string>>,
  rowGap: ?string | Array<ComponentPropResponsiveObject<string>>,
  padding: ?string | Array<ComponentPropResponsiveObject<string>>,
};

TatePaginator.defaultProps = {
  sectionId: null,
};

export default function TatePaginator({
  listId,
  sectionId,
  history,
  biAction,
  isLazyloadImages,
  gridGap,
  colGap,
  rowGap,
  padding,
  namedBgc,
}: PropsType) {
  const { theme, } = useFela();
  const initialIndex = 8;
  return (
    <Paginator
      initialPage={1}
      listId={listId}
      sectionId={sectionId}
      history={history}
      listQuery={TATE_LIST_QUERY}
      colTemplate={[ { until: 'l', value: '1fr', }, { from: 'l', value: '2fr 5fr 5fr', }, ]}
      rowTemplate={null}
      loadButtonGridColumn={[ { until: 'l', value: '1', }, { from: 'l', value: '2 / -1', }, ]}
      gridGap={gridGap}
      colGap={colGap}
      rowGap={rowGap}
      padding={padding}
      namedBgc={namedBgc}
      buttonVariant="commercial"
    >
      {items => items.map((item, idx) => (
        <TateLabelsTeaser
          index={initialIndex + idx}
          data={item}
          biAction={setBiAction(idx, item, biAction)}
          isLazyloadImages={isLazyloadImages}
          key={item.contentId}
          theme={theme}
        />
      ))}
    </Paginator>
  );
}
