// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';
import ListView from '../../../ListView/NewListView';
import { MuliTeaser1, MuliTeaser2, MuliTeaser3, MuliTeaser4, MuliTeaser5, MuliTeaser6, } from './MuliTeaser';
import setBiAction from '../../../../utils/setBiAction';
import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';

const gridTemplate = [
  { until: 's',
    value: `
      "col1"
      "col2"
      "col3"
    `,
  },
  { from: 's',
    until: 'l',
    value: `
      "col1 col1"
      "col2 col3"`,
  },
  { from: 'l',
    value: '"col1 col1 col2 col3"',
  },
];

const gridGap = '3rem';

const gridTemplateColumns = [
  {
    until: 's',
    value: '1fr',
  },
  {
    from: 's',
    until: 'l',
    value: '1fr 1fr',
  },
  {
    from: 'l',
    value: 'repeat(4, 1fr)',
  },
];

const columnsGrid = [
  'auto 1fr',
  [ { from: 's', value: '3fr 4fr', }, ],
  '1fr auto',
];

function columnStyle({ theme, }) {
  return {
    display: 'grid',
    gridGap,
  };
}

const teasers = [ MuliTeaser1, MuliTeaser2, MuliTeaser3, MuliTeaser4, MuliTeaser5, MuliTeaser6, ];

function getTeaser(index) {
  return teasers[index];
}

export default function MuliView({
  list,
  isLazyloadImages,
  gaAction,
  biAction,
}: ListViewProps) {
  const { css, } = useFela();

  // Join any two items together. two items per column.
  const columns = list.items.reduce((cols, item, index) => {
    const colIdx = Math.floor(index / 2);
    if (!cols[colIdx]) {
      cols.push([]);
    }
    cols[colIdx].push(item);

    return cols;
  }, []);

  return (
    <ListView
      areasTemplate={gridTemplate}
      colTemplate={gridTemplateColumns}
      gridGap={gridGap}
      padding={[ { until: 's', value: '0 2rem', }, { from: 's', until: 'xl', value: '0 4rem', }, { from: 'xl', value: '0', }, ]}
      attrs={{
        'data-test': 'muli',
      }}
    >
      {columns.map((col, colIndex) => (
        <div className={css(columnStyle, { gridArea: `col${colIndex + 1}`, gridTemplateRows: columnsGrid[colIndex], })}>
          {col.map((item, itemIndex) => {
            const index = (colIndex * 2) + itemIndex;
            const Teaser = getTeaser(index);
            return <Teaser key={item.contentId} item={item} biAction={setBiAction(index, item, biAction)} />;
          })}
        </div>
      ))}

    </ListView>
  );
}
