// @flow
import * as React from 'react';
import { BOXY_INVERSE_LIST_QUERY, } from '@haaretz/graphql';
import BoxyView from './BoxyView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType.js';
import ListWrapper from '../../ListWrapper.js';

type BoxyInverseHdcProps = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
}

export default function BoxyInverseHdcWrapper(props: BoxyInverseHdcProps) {
  return (
    <ListWrapper {...props} query={BOXY_INVERSE_LIST_QUERY} view="BoxyInverseHdc">
      {dataProps => (
        <BoxyView
          {...dataProps}
          isColorInverse
        />
      )}
    </ListWrapper>
  );
}
