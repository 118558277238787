// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';

import setBiAction from '../../../../utils/setBiAction';
import Section from '../../../AutoLevels/Section';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import TateLabelsTeaser from './TateLabelsTeaser';

import type { ContinuableListViewProps, } from '../../../../flowTypes/ListViewProps';
import TateLabelsPaginator from './TateLabelsPagintor';

const isValidItem = item => item && item.representedContent !== 'UNDEFINED.UNDEFINED';

TateLabelsView.defaultProps = {
  biAction: null,
  gaAction: null,
  sectionId: null,
  isLazyloadImages: true,
};

export default function TateLabelsView({
  list,
  listId,
  history,
  isLazyloadImages,
  biAction,
  sectionId,
}: ContinuableListViewProps): React.Node {
  const { dfp, items, hasPagination, itemsCount, ...restOfList } = list || {};
  const { theme, } = useFela();
  const hasHeader = restOfList.title || restOfList.commercialLinks?.length || restOfList.extraLinks;

  return (
    <ListView
      colTemplate={[ { until: 'l', value: '1fr', }, { from: 'l', value: '2fr 5fr 5fr', }, ]}
      rowGap={[ { until: 's', value: '2rem', }, ]}
      padding={[ { until: 's', value: '0 2rem', }, { from: 's', value: '0', }, ]}
      marginTop={[ { until: 's', value: 2, }, { from: 's', value: 4, }, ]}
      Paginator={hasPagination && items?.length >= itemsCount ? ({ namedBgc, gridGap, colGap, rowGap, padding, }) => (
        <TateLabelsPaginator
          listId={listId}
          sectionId={sectionId}
          history={history}
          biAction={biAction}
          isLazyloadImages={isLazyloadImages}
          gridGap={gridGap}
          colGap={colGap}
          rowGap={rowGap}
          padding={padding}
          namedBgc={namedBgc}
        />
      ) : null}
      attrs={{
        'data-test': 'tateLabels',
      }}
    >
      {hasHeader ? (
        <ListViewHeader
          {...restOfList}
          extraLinks={null}
          biAction={biAction}
          disableGridArea
          isSticky
          isCommercial
          miscStyles={{
            gridArea: [ { from: 'l', value: `1/1/${Math.ceil(items.length / 2) + 1}/2`, }, ],
          }}
        />
      ) : null}
      <Section isFragment>
        {items.filter(isValidItem).map((item, index) => {
          const teaserBiAction = setBiAction(index, item, biAction);
          return (
            <TateLabelsTeaser
              index={index}
              biAction={teaserBiAction}
              data={item}
              isLazyloadImages={isLazyloadImages}
              key={item.contentId}
              theme={theme}
            />
          );
        })}
      </Section>
    </ListView>
  );
}
