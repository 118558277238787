// @flow
import * as React from 'react';
import Picture from '../../../Image/Picture';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import getPictureAssets from '../../../../utils/getPictureAssets';

import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

type ZappItemProps = {
  data: TeaserDataType,
  isLazyloadImages: boolean,
  hideImageOnMobile: boolean,
  biAction: ?() => void,
  theme: Object,
  isThemarker?: boolean,
};

ZappLabelsItem.defaultProps = {
  isLazyloadImages: true,
  hideImageOnMobile: false,
  isThemarker: false,
};

const areasMediaObjTeaser = `
"media content"
"media footer"
`;

const areasStackedTeaser = '"media" "content" "footer"';
export default function ZappLabelsItem({
  data,
  isLazyloadImages,
  hideImageOnMobile,
  biAction,
  theme,
  isThemarker,
}: ZappItemProps): React.Node {
  const { teaser, } = theme.zappStyle;

  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      key={data.contentId}
      data={data}
      onClick={biAction}
      areasTemplate={[ { until: 'l', value: areasMediaObjTeaser, }, { from: 'l', value: areasStackedTeaser, }, ]}
      colTemplate={[ { until: 's', value: '18rem 1fr 0', }, { from: 's', until: 'l', value: '1fr 1fr 0', }, ]}
      rowTemplate={[
        {
          until: 's',
          value: '1fr auto',
        },
        { from: 's', until: 'l', value: 'auto 1fr', },
        { from: 'l', value: 'auto 1fr auto', },
      ]}
      gridGap={teaser.gridGap}
      miscStyles={{
        fontFamily: theme.fontStacks.commercial,
        outline: `1px solid ${theme.color('neutral', '-5')}`,
        backgroundColor: theme.color('neutral', '-10'),
        ...(data.highlight ? { paddingBlockEnd: [ { from: 's', value: '0.5rem', }, ], } : {}),
      }}
    >
      <TeaserMedia
        data={data}
        miscStyles={hideImageOnMobile ? { display: [ { until: 's', value: 'none', }, ], } : null}
        onClick={biAction}
      >
        {image ? (
          <Picture
            lazyLoad={isLazyloadImages}
            {...getPictureAssets({
              bps: theme.bps,
              imgData: image,
              defaultImgOptions: {
                sizes: '108px',
                aspect: 'square',
                widths: [ 108, 216, ],
              },
              sources: [
                {
                  from: 's',
                  aspect: 'headline',
                  sizes: [
                    { from: 'xl', size: isThemarker ? '385px' : '280px', },
                    { from: 'l', size: isThemarker ? '310px' : '226px', },
                    { from: 'm', size: '360px', },
                    { from: 's', size: '276px', },
                  ],
                  widths: [ 226, 276, 280, 310, 390, 552, 720, ],
                },
              ],
            })}
          />
        ) : null}
      </TeaserMedia>
      <TeaserHeader
        {...data}
        typeScale={teaser.headerTypeScaleLabel}
        onClick={biAction}
        isGridItem
        wrapperMiscStyles={{
          padding: [ { until: 's', value: '1rem 0rem 1rem', },
            { from: 's', until: 'xl', value: '1rem', },
            { from: 'xl', value: '0rem 1rem 3rem', }, ],
        }}
      />
    </Teaser>
  );
}
