// @flow
/* global window document */

import * as React from 'react';
import { useFela, } from 'react-fela';
import ListView from '../../../ListView/NewListView';
import NilsTeaser from './NilsTeaser';
import BingeListViewHeader from '../../../ListViewHeader/BingeListViewHeader';
import FilterUnreadArticles from '../../FilterUnreadArticles/FilterUnreadArticles';
import MoreTeaser from './MoreTeaser';
import setBiAction from '../../../../utils/setBiAction';
import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';

export default function NilsView({
  list,
  listId,
  isLazyloadImages,
  gaAction,
  biAction,
}: ListViewProps) {
  const { css, } = useFela();
  const articleIds = list.items.map(item => item.representedContent || item.contentId);

  return (
    <ListView
      gridGap={[ { until: 's', value: '3rem', }, { from: 's', value: '4rem', }, ]}
      colTemplate={[ { until: 's', value: '1fr', }, { from: 's', until: 'l', value: '1fr 1fr', }, { from: 'l', value: '1fr 1fr 1fr', }, ]}
      rowTemplate={[ { until: 's', value: 'auto', }, ]}
      marginTop={[ { until: 's', value: '0', }, ]}
      padding={[
        { until: 's', value: '2rem', },
        { from: 's', until: 'l', value: '4rem', },
        { from: 'l', value: '4rem 0 0 0', },
      ]}
      attrs={{
        'data-test': 'nils',
      }}
    >
      <FilterUnreadArticles articleIds={articleIds}>
        {({ filterOutUnreadArticles: { readArticles, }, }) => (
          <React.Fragment>
            <div className={css({ gridColumn: '1 / -1', })}>
              <BingeListViewHeader
                miscStyles={{
                  marginLeft: [
                    { until: 's', value: '-2rem', },
                    { from: 's', until: 'l', value: '-4rem', },
                  ],
                  marginRight: [
                    { until: 's', value: '-2rem', },
                    { from: 's', until: 'l', value: '-4rem', },
                  ],
                }}
                readArticles={readArticles}
                onClick={() => {
                  const itemsAnchor = document.getElementById(`list-${listId}-start`);
                  itemsAnchor && window.scrollTo({ top: itemsAnchor.offsetTop, behavior: 'smooth', });
                }}
                {...list}
              />
              <div id={`list-${listId}-start`} />
            </div>
            { list.items.map((item, index) => (
              <NilsTeaser
                key={item.contentId}
                item={item}
                isAnimated
                markedAsRead={readArticles.includes(item.contentId)}
                biAction={setBiAction(index, item, biAction)}
              />
            )) }
            { list.url && list.urlDescription ? <MoreTeaser {...list} /> : null }
          </React.Fragment>
        )}
      </FilterUnreadArticles>
    </ListView>
  );
}
