// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { useInView, } from 'react-intersection-observer';
import HtzLink from '../../../HtzLink/HtzLink';
import IconBack from '../../../Icon/icons/IconBack';
import checkSiteFromConfig from '../../../../utils/checkSiteFromConfig';

const { isHDC, } = checkSiteFromConfig();

type PropsType = {
    url: string,
    urlDescription: string,
};

function squareStyle({ theme, }) {
  return {
    position: 'relative',
    ':before': {
      position: 'relative',
      zIndex: theme.getZIndex('below'),
      content: '""',
      display: 'block',
      paddingBottom: '100%',
    },
  };
}

function squareContentStyle({ theme, }) {
  return {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  };
}

function teaserStyle({ theme, inView, }) {
  return {
    ...(inView
      ? {
        opacity: 1,
        transform: 'translateY(0)',
      }
      : {
        opacity: 0.05,
        transform: 'translateY(25%)',
      }),
    transition: 'all 0.5s linear',
    border: `1px solid ${theme.color('neutral', '-3')}`,
    width: '100%',
  };
}

function teaserContentStyle({ theme, }) {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingLeft: '8rem',
    paddingRight: '8rem',
    extend: [
      theme.type(7, { untilBp: 's', }),
      theme.type(5, { fromBp: 's', untilBp: 'xl', }),
      theme.type(8, { fromBp: 'xl', }),
    ],
  };
}

function iconStyle({ theme, }) {
  return {
    borderRadius: '50%',
    backgroundColor: theme.color('quaternary'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '14rem',
    height: '14rem',
    marginTop: '3rem',
    extend: [
      theme.mq({ from: 's', until: 'l', }, { width: '10rem', height: '10rem', }),
      theme.mq({ from: 'l', until: 'xl', }, { width: '12rem', height: '12rem', }),
      theme.mq({ from: 'xl', }, { width: '16rem', height: '16rem', }),
    ],
  };
}

export default function MoreTeaser({ url, urlDescription, }: PropsType) {
  const [ ref, inView, ] = useInView({ triggerOnce: true, threshold: 0.25, });
  const { theme, css, } = useFela({ inView, });
  return (
    <div className={css(teaserStyle, squareStyle)} ref={ref}>
      <HtzLink
        className={css(teaserContentStyle, squareContentStyle)}
        href={url}
      >
        {urlDescription}

        <div className={css(iconStyle)}>
          <IconBack
            size={[
              { until: 's', value: 8, },
              { from: 's', until: 'l', value: 5, },
              { from: 'l', until: 'xl', value: 6, },
              { from: 'xl', value: 7, },
            ]}
            miscStyles={{ transform: isHDC ? 'rotate(180deg)' : undefined, }}
          />
        </div>
      </HtzLink>
    </div>
  );
}
